import { InboxIcon, SendIcon } from '@klausapp/ui-kit/icons'
import i18n from '@/i18n'

import { FilterDirection } from '../../../types'

export const getFeedbackDirections = () => [
  {
    value: FilterDirection.Received,
    icon: InboxIcon,
    label: i18n.t('dashboard.feedback_direction_received'),
  },
  {
    value: FilterDirection.Given,
    icon: SendIcon,
    label: i18n.t('dashboard.feedback_direction_given'),
  },
]
