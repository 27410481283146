import { type Config } from 'dompurify'

const BASE_CONFIG: Config = {
  ADD_ATTR: ['target'],
  FORBID_ATTR: ['style'],
  ALLOWED_TAGS: [
    'div',
    'a',
    'b',
    'strong',
    'br',
    'hr',
    'p',
    'i',
    's',
    'u',
    'img',
    'table',
    'thead',
    'tbody',
    'tfoot',
    'tr',
    'td',
    'th',
    'blockquote',
    'em',
    'span',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'pre',
    'code',
    'sup',
    'sub',
    'ol',
    'ul',
    'li',
    'del',
    'small',
  ],
}

const LINKS_ONLY: Config = {
  ...BASE_CONFIG,
  ALLOWED_TAGS: ['a'],
}

const ALLOW_INLINE_STYLES: Config = {
  ...BASE_CONFIG,
  FORBID_ATTR: BASE_CONFIG.FORBID_ATTR?.filter((attr) => attr !== 'style'),
}

const USER_ENTERED_CONTENT: Config = {
  ...BASE_CONFIG,
  FORBID_TAGS: ['p', 'img', 'h1'],
}

const ALLOW_LINE_BREAKS: Config = {
  ...BASE_CONFIG,
  ALLOWED_TAGS: ['br'],
}

export const SANITIZER_PRESETS = {
  BASE_CONFIG,
  LINKS_ONLY,
  ALLOW_INLINE_STYLES,
  ALLOW_LINE_BREAKS,
  USER_ENTERED_CONTENT,
} as const
