function envValue(key: string, fallback?: string): string
function envValue(key: string, fallback?: boolean): string | boolean
function envValue(key: string, fallback?: number): string | number
function envValue(key: string, fallback?: string | boolean | number) {
  const value = process.env[key]
  if (value !== undefined) return value
  if (fallback === undefined) throw new Error(`Environment variable '${key}' is not set.`)
  return fallback
}

declare global {
  interface Window {
    __CONFIG__?: Record<string, number | string | boolean>
  }
}

interface ConfigFunctionHelpers {
  envString(key: string, fallback?: string | undefined): string
  envBoolean(key: string, fallback?: boolean): boolean
  envNumber(key: string, fallback?: number): number
}

/**
 * Load configuration isomorphically
 * @param configFunction Configuration object
 */
export function loadConfig<O extends Record<string, unknown>>(configFunction: (helpers: ConfigFunctionHelpers) => O) {
  if (typeof window !== 'undefined') {
    const noop = (k: string): any => k
    const propertyMap = Object.fromEntries(
      Object.entries(configFunction({ envNumber: noop, envString: noop, envBoolean: noop })).map((a) => a.reverse()),
    )

    // Reverse lookup with config object
    const injectedValue = <T extends string | number | boolean>(key: string) => {
      if (!window.__CONFIG__) throw new Error(`'window.__CONFIG__' cannot be found.`)
      return window.__CONFIG__[propertyMap[key]] as T
    }

    return configFunction({ envNumber: injectedValue, envString: injectedValue, envBoolean: injectedValue })
  }

  if (typeof process !== 'undefined') {
    return configFunction({
      envBoolean: (key: string, fallback: boolean) => {
        const value = envValue(key, fallback)
        if (typeof value === 'boolean') return value
        if (!['true', 'false'].includes(value)) throw new Error(`Invalid boolean env value for '${key}'`)
        return value === 'true'
      },
      envNumber: (key: string, fallback: number) => {
        const value = envValue(key, fallback)
        if (typeof value === 'number') return value
        const num = parseFloat(value)
        if (!isNaN(num)) return num
        throw new Error(`Invalid number env value for '${key}'`)
      },
      envString: envValue,
    })
  }

  throw new Error('Unknown host environment.')
}
