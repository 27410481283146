import { ref } from 'vue'
import { getUnreadPinsCount } from '@/modules/pins/api'
import { getUnreadSessionsCount } from '@/modules/coaching/api'

export const pinsCount = ref(0)
export const coachingSessionsCount = ref(0)

export async function getPinsCount() {
  const { count } = await getUnreadPinsCount()
  pinsCount.value = count
}

export async function getCoachingSessionsCount() {
  const { count } = await getUnreadSessionsCount()
  coachingSessionsCount.value = parseInt(count, 0)
}
