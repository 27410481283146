import type {
  Pin,
  PinFoldersResponse,
  SearchPinsResponse,
  PinsInFolderResponse,
  CreatePinPayload,
  EditPinResponse,
  EditPinPayload,
  PinCommentsResponse,
  CreatePinCommentPayload,
  PinComment,
  ListPinFoldersPayload_SortField,
  PinReferenceResponse,
  PinReference,
  HasPinsForResponse,
  UnreadPinsResponse,
} from '@klausapp/services/pins'

import { api } from '@/api'

export const getPinReferencePreview = ({ type, value }: PinReference) =>
  api
    .extend({ hooks: { afterResponse: undefined } })
    .extend({ hooks: { afterResponse: [] } })
    .get(`pins/reference/${type}/${value}`)
    .json<PinReferenceResponse>()

export const createPin = (json: CreatePinPayload) => api.post('pins', { json }).json<Pin>()

export const editPin = (pinId: string, json: Omit<EditPinPayload, 'id'>) =>
  api.put(`pins/${pinId}`, { json }).json<EditPinResponse>()

export const listPinFolders = (sort: ListPinFoldersPayload_SortField) =>
  api.get('pins/folders', { searchParams: { sort } }).json<PinFoldersResponse>()

export const searchPins = (query: string) =>
  api.get('pins/search', { searchParams: { query } }).json<SearchPinsResponse>()

export const getPinsInFolder = (folderId: string) => api.get(`pins/folders/${folderId}`).json<PinsInFolderResponse>()

export const getPinsByUserFolder = (userId: string, params?: { fromDate?: string; toDate?: string }) => {
  const query = new URLSearchParams()
  if (params?.fromDate) query.append('fromDate', params.fromDate)
  if (params?.toDate) query.append('toDate', params.toDate)

  return api
    .extend({ hooks: { afterResponse: undefined } })
    .extend({ hooks: { afterResponse: [] } })
    .get(`pins/folders/by-user/${userId}?${query}`)
    .json<PinsInFolderResponse>()
}

export const deletePin = (pinId: string) => api.delete(`pins/${pinId}`).json()

export const getPinComments = (pinId: string) => api.get(`pins/${pinId}/comments`).json<PinCommentsResponse>()

export const createPinComment = (pinId: string, json: Omit<CreatePinCommentPayload, 'pinId'>) =>
  api.post(`pins/${pinId}/comments`, { json }).json<PinComment>()

export const deletePinComment = (pinId: string, commentId: string) =>
  api.delete(`pins/${pinId}/comments/${commentId}`).json()

export const editPinComment = (pinId: string, commentId: string, body: string) =>
  api.put(`pins/${pinId}/comments/${commentId}`, { json: { body } }).json()

export const addPinParticipant = (pinId: string, userId: string) =>
  api.post(`pins/${pinId}/participants/${userId}`).json()

export const removePinParticipant = (pinId: string, userId: string) =>
  api.delete(`pins/${pinId}/participants/${userId}`).json()

export const getUnreadPinsCount = () => api.get('pins/unread-count').json<UnreadPinsResponse>()

export const markPinsViewed = (ids: string[]) => api.post('pins/mark-viewed', { json: { ids } }).json()

export const hasPinsFor = ({ type, value }: PinReference) =>
  api.get(`pins/exists-for/${type}/${value}`).json<HasPinsForResponse>()
