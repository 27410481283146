import { computed, type Ref } from 'vue'
import { createEventHook } from '@vueuse/core'

import type { PropsWithDefaults, UpdateParams, Modes } from '../../types'
import { ALL_ID } from '../../constants'
import { getId, getIds } from '../utils'
import useSortedList from '../common/useSortedList'
import useSelectedList from '../common/useSelectedList'
import useFlatOptions from '../common/useFlatOptions'

export default function useMultipleAllList<
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
>(props: PropsWithDefaults<T, P, L, M, V, O>, emit: any, searchQuery: Ref<string>) {
  const updateResults = createEventHook<UpdateParams<T, P, M, O>>()

  const { reorderList, sortedOptions, sortedGroups } = useSortedList(props)
  const { selectedValues, excludedValues, updateModelValue, onUpdate } = useSelectedList<T, P, L, M, V, O>(props)
  const { flatOptions } = useFlatOptions(props)

  onUpdate(updateResults.trigger)

  const isSelected = (option: T) => {
    if (showAllGroup.value && allSelected.value) return option[props.propToCheck] === ALL_ID

    return selectedIds.value.includes(option[props.propToCheck])
  }

  const selectOption = (option: T) => {
    selectedValues.value = getIds<T, P, L, M, V, O>(props.modelValue, props).concat(option[props.propToCheck])
  }

  const deselectOption = (option: T) => {
    selectedValues.value = getIds<T, P, L, M, V, O>(props.modelValue, props).filter(
      (id) => id !== option[props.propToCheck],
    )
  }

  const toggle = (option: T) => {
    if (isSelected(option)) {
      deselectOption(option)
    } else {
      selectOption(option)
    }

    updateModelValue(selectedValues.value, [])
  }

  const allSelected = computed(() => props.modelValue.length === flatOptions.value.length)

  const selectedIds = computed(() => props.modelValue.map((item: T | T[P]) => getId(item, props)))

  const simplifiedSelectedIds = computed(() => (allSelected.value ? [ALL_ID] : selectedIds.value))

  const showAllGroup = computed(() => !searchQuery.value && !!props.selectAllLabel && !!flatOptions.value.length)

  const addOrSelectOption = (label: string) => {
    const option = flatOptions.value.find(
      (option) => (option[props.label] as string).toLowerCase() === label.toLowerCase(),
    )
    if (option) {
      if (isSelected(option)) return

      selectOption(option)
      updateModelValue(selectedValues.value, [])
    } else {
      emit('add-option', label)
    }
  }

  return {
    excludedValues,
    isSelected,
    isIndeterminate: (_option: T) => false,
    reorderList,
    selectedIds: simplifiedSelectedIds,
    sortedOptions,
    sortedGroups,
    showAllGroup,
    toggle,
    addOrSelectOption,
    onUpdate: updateResults.on,
  }
}
