import { v4 as uuid } from 'uuid'
import type { PropsWithDefaults, OptionGroup, DropdownProps, Selected, Modes } from '../types'

export const getGroupOptions = <T>(group: OptionGroup<T>[]): T[] => group.map(({ options }) => options).flat()

export const getOptionValue = <T extends object, P extends keyof T, O extends boolean>(
  option: T,
  propToCheck: P,
  isObject?: O,
) => (isObject ? option : option[propToCheck])

export const sortOptions = <T extends object, P extends keyof T>(
  a: T,
  b: T,
  initialSelected: any[],
  propToCheck: P,
) => {
  const aSelected = initialSelected.includes(a[propToCheck])
  const bSelected = initialSelected.includes(b[propToCheck])

  if (aSelected && !bSelected) return -1
  if (!aSelected && bSelected) return 1

  return 0
}

export const getId = <
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
>(
  option: T | T[P],
  props: PropsWithDefaults<T, P, L, M, V, O>,
) => (props.isObject ? (option as T)[props.propToCheck] : (option as T[P]))

export const getIds = <
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
>(
  options: T[] | T[P][],
  props: PropsWithDefaults<T, P, L, M, V, O>,
) => options.map((option: T | T[P]) => getId(option, props))

export const getOptionGroups = <T extends object>(options: T[], total?: number): OptionGroup<T>[] => [
  {
    id: uuid(),
    options,
    total,
    name: undefined,
  },
]

export const getValue = <
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
>(
  option: T,
  props: PropsWithDefaults<T, P, L, M, V, O>,
) => (props.isObject ? option : option[props.propToCheck])

export const mapIdsToValues = <
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
>(
  ids: T[P][],
  props: DropdownProps<T, P, L, M, V, O>,
) => {
  return props.isObject
    ? (ids
        .map((value) => {
          const options = props.groups ? props.groups.map((g) => g.options).flat() : props.options || []
          return options.find((option) => option[props.propToCheck] === value)
        })
        .filter(Boolean) as Selected<T, P, M, O>)
    : (ids as Selected<T, P, M, O>)
}
