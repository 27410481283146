import { ref, onBeforeMount } from 'vue'

import { oldApi } from '@/api'

interface AccountItem {
  id: number
  name: string
}

const getAccountList = async () => {
  // TODO: Write a new endpoint for this
  const { data: payments } = await oldApi.get('payments').json<any>()
  const accounts = payments.map((a) => ({ id: a.id, name: a.name }))
  return accounts as AccountItem[]
}

const accountsRef = ref<AccountItem[]>([])

const reload = async () => {
  accountsRef.value = await getAccountList()
}

export const useAccountList = (): [typeof accountsRef, typeof reload] => {
  onBeforeMount(reload)

  return [accountsRef, reload]
}
