import resolveConfig from 'tailwindcss/resolveConfig'
import { useBreakpoints } from '@vueuse/core'
import tailwindConfig from '../../tailwind.config.cjs'

const configScreens = resolveConfig(tailwindConfig).theme.screens

// Exposes our Tailwind breakpoints as a map
export const screens = Object.fromEntries(Object.entries(configScreens).map(([k, v]) => [k, parseFloat('' + v)]))

export default function () {
  const breakpoints = useBreakpoints(screens)
  return { breakpoints }
}
