import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-tags-input" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "q-tags-input__field text-sm leading-5" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 1,
  class: "text-gray-60 mt-1.5 text-xs font-normal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x_icon = _resolveComponent("x-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: "text-gray-70 mb-2 flex w-full items-center justify-between text-xs font-medium leading-none"
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1 /* TEXT */),
          _renderSlot(_ctx.$slots, "label-suffix")
        ], 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (tag, i) => {
        return _renderSlot(_ctx.$slots, "tag", {
          tag: tag,
          idx: i,
          remove: () => _ctx.removeTag(i)
        }, () => [
          (_openBlock(), _createElementBlock("span", {
            key: tag + i,
            class: "q-tags-input__tag text-sm leading-5"
          }, [
            _createElementVNode("span", null, _toDisplayString(tag), 1 /* TEXT */),
            _createElementVNode("button", {
              type: "button",
              class: "q-tags-input__remove text-lg leading-none",
              onClick: ($event: any) => (_ctx.removeTag(i))
            }, [
              _createVNode(_component_x_icon, { class: "icon-m" })
            ], 8 /* PROPS */, _hoisted_4)
          ]))
        ])
      }), 256 /* UNKEYED_FRAGMENT */)),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.multiline ? 'textarea' : 'input'), _mergeProps(_ctx.$attrs, {
        id: _ctx.id,
        ref: "inputRef",
        class: ["q-tags-input__text text-sm", _ctx.multiline && 'q-tags-input__multiline'],
        value: _ctx.newTag,
        onInput: _ctx.setNewTag,
        onKeydown: _ctx.handleKeydown,
        onPaste: _withModifiers(_ctx.handlePaste, ["prevent"]),
        onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addTag(_ctx.newTag)))
      }), null, 16 /* FULL_PROPS */, ["id", "class", "value", "onInput", "onKeydown", "onPaste"]))
    ]),
    (_ctx.showMessage)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
          _createTextVNode(_toDisplayString(_ctx.message) + " ", 1 /* TEXT */),
          _renderSlot(_ctx.$slots, "message")
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}