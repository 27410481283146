import config from '@/config'
import analytics from '@/utils/analytics'
import { toast } from '@/components/toast'
import i18n from '@/i18n'

const reload = () => {
  analytics.appUpdateReload()
  window.location.reload()
}

export const listenForAppUpdate = () => {
  if (module.hot) return

  const timer = setInterval(async () => {
    try {
      const { gitCommit } = await fetch(`${config.baseUrl}/version`)
        .then((r) => r.json())
        .catch()
      if (gitCommit === 'maintenance') return window.location.reload()

      if (gitCommit !== config.gitCommit) {
        console.info('Application updated to %s from %s', gitCommit, config.gitCommit)
        clearInterval(timer)
        toast({
          timeout: 0,
          message: i18n.t('modules.application.toast_message', {
            buttonStart: '<button type="button" class="toast__button">',
            buttonEnd: '</button>',
          }),
          callback: () => reload(),
        })
      }
    } catch {
      console.error('Failed to check version')
    }
  }, 60000)
}
