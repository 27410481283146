import { type AsyncComponentLoader, defineAsyncComponent } from 'vue'

const logos: Record<string, AsyncComponentLoader[]> = {
  standard: [defineAsyncComponent(() => import('./assets/logos/logo-main.svg'))],
  construction: [defineAsyncComponent(() => import('./assets/logos/logo-main.svg'))],
  xmas: [defineAsyncComponent(() => import('./assets/logos/logo-main.svg'))],
  halloween: [defineAsyncComponent(() => import('./assets/logos/logo-main.svg'))],
  pride: [defineAsyncComponent(() => import('./assets/logos/logo-main.svg'))],
  ukraine: [defineAsyncComponent(() => import('./assets/logos/logo-main.svg'))],
  wordmark: [defineAsyncComponent(() => import('./assets/logos/logo-wordmark.svg'))],
}

export default logos
