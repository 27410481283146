import { api } from '@/api'

interface CalibrationUser {
  id: number
  name: string
  avatar: string
}
export interface CalibrationSession {
  sessionId: string
  createdBy: CalibrationUser | null
  createdAt: string
  updatedBy: CalibrationUser | null
  updatedAt: string
  title: string
  dueAt: string
}

export interface CreateCalibrationSessionPayload {
  title: string
  dueAt: string
}

export interface EditCalibrationSessionPayload {
  title?: string
  dueAt?: string
}

export const getCalibrationSessions = (workspaceId: number) =>
  api
    .get('calibration/list', {
      headers: { 'X-Klaus-Workspace': workspaceId.toString() },
    })
    .json<{ data: CalibrationSession[]; total: string }>()

export const createCalibrationSession = (workspaceId: number, calibration: CreateCalibrationSessionPayload) =>
  api
    .post('calibration', {
      headers: { 'X-Klaus-Workspace': workspaceId.toString() },
      json: calibration,
    })
    .json<CalibrationSession[]>()

export const editCalibrationSession = (
  workspaceId: number,
  sessionId: string,
  calibration: EditCalibrationSessionPayload,
) =>
  api
    .put(`calibration/${sessionId}`, {
      headers: { 'X-Klaus-Workspace': workspaceId.toString() },
      json: calibration,
    })
    .json<CalibrationSession[]>()

export const deleteCalibrationSession = (workspaceId: number, sessionId: string) =>
  api.delete(`calibration/${sessionId}`, {
    headers: { 'X-Klaus-Workspace': workspaceId.toString() },
  })
