import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["input-id"]
const _hoisted_2 = {
  key: 0,
  class: "q-select__label flex items-center justify-between"
}
const _hoisted_3 = {
  key: 0,
  class: "q-select__field-icon"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["title"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  "data-testid": "multiselect-tag",
  class: "multiselect-tag"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_global_events = _resolveComponent("global-events")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_check_icon = _resolveComponent("check-icon")!
  const _component_x_icon = _resolveComponent("x-icon")!
  const _component_multiselect = _resolveComponent("multiselect")!

  return (_openBlock(), _createElementBlock("label", {
    ref: "labelRef",
    class: _normalizeClass(["q-select", [
      _ctx.$attrs.class,
      {
        'q-select__multiline': _ctx.multiline,
        'q-select__loading': _ctx.isLoading,
        'q-select__full-selected': _ctx.fullWidthSelected,
        'q-select__with-icon': !!_ctx.$slots.icon && !_ctx.redesigned,
        'q-select__searchable': _ctx.searchable,
        'q-select--redesigned': _ctx.redesigned,
        'q-select--condensed': _ctx.condensed,
        'q-select--borderless': _ctx.borderless,
        'q-select--open': _ctx.isOpen,
        'q-select--error': _ctx.error,
      },
    ]]),
    "input-id": _ctx.inputId,
    onInput: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createVNode(_component_global_events, {
      "no-filtering": "",
      onKeydown: [
        _withKeys(_withModifiers(_ctx.handleTabPress, ["shift","exact"]), ["tab"]),
        _withKeys(_withModifiers(_ctx.handleTabPress, ["exact"]), ["tab"])
      ]
    }, null, 8 /* PROPS */, ["onKeydown"]),
    (_ctx.fieldLabel)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.fieldLabel), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("span", {
      class: _normalizeClass(["q-select__field text-sm font-normal", {
        'q-select__field--disabled': _ctx.disabled,
        dark: _ctx.theme === 'dark',
      }])
    }, [
      (_ctx.$slots.icon)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _renderSlot(_ctx.$slots, "icon", { open: _ctx.isOpen })
          ]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_multiselect, _mergeProps({ id: _ctx.inputId }, _ctx.selectAttrs, {
        ref: "select",
        value: _ctx.cloneDeep(_ctx.toRaw(_ctx.modelValue)),
        disabled: _ctx.disabled,
        "value-prop": _ctx.valueProp,
        "hide-selected": false,
        "can-clear": _ctx.canClear,
        "can-deselect": false,
        "clear-on-select": _ctx.clearOnSelect,
        placeholder: _ctx.placeholder,
        "no-results-text": _ctx.noResultsText || _ctx.$t('universal.no_items_found'),
        "no-options-text": _ctx.noOptionsText,
        label: _ctx.label,
        searchable: _ctx.searchable,
        options: _ctx.options,
        "open-direction": _ctx.computedOpenDirection,
        class: ["text-sm", {
          'font-medium': _ctx.condensed,
          'font-normal': !_ctx.condensed,
        }],
        classes: {
          dropdown: _ctx.condensed ? 'multiselect-dropdown text-xs leading-5' : 'multiselect-dropdown text-sm',
          groupLabel: 'multiselect-group-label text-xs',
          optionDisabled: _ctx.showDisabledCursor ? 'cursor-not-allowed' : 'pointer-events-none',
          optionPointed: 'bg-transparent',
        }
      }, _toHandlers(_ctx.listeners), {
        "onUpdate:modelValue": _ctx.updateValue,
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
        onOpen: _ctx.handleOpen,
        onClose: _ctx.handleClose
      }), {
        option: _withCtx(({ option, isSelected }: any) => [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.optionWrapper),
            onClick: ($event: any) => (_ctx.handleOptionClick(option, isSelected(option), $event))
          }, [
            _renderSlot(_ctx.$slots, "option", {
              option: option,
              selected: isSelected(option)
            }, () => [
              (option.icon)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(option.icon), {
                    key: 0,
                    class: _normalizeClass(_ctx.$style.userIcon)
                  }, null, 8 /* PROPS */, ["class"]))
                : ('avatar' in option)
                  ? (_openBlock(), _createBlock(_component_q_avatar, {
                      key: 1,
                      size: 24,
                      src: typeof option.avatar === 'string' ? option.avatar : undefined,
                      component: typeof option.avatar === 'object' ? option.avatar : undefined,
                      username: option[_ctx.label],
                      class: _normalizeClass(_ctx.$style.avatar)
                    }, null, 8 /* PROPS */, ["src", "component", "username", "class"]))
                  : _createCommentVNode("v-if", true),
              _renderSlot(_ctx.$slots, "option-label", {
                option: option,
                label: option[_ctx.label]
              }, () => [
                _createElementVNode("span", {
                  class: "w-full truncate",
                  title: option[_ctx.label]
                }, _toDisplayString(option[_ctx.label]), 9 /* TEXT, PROPS */, _hoisted_5)
              ]),
              (isSelected(option))
                ? (_openBlock(), _createBlock(_component_check_icon, {
                    key: 2,
                    class: _normalizeClass(['text-active-primary', _ctx.condensed && _ctx.$style.smallIcon])
                  }, null, 8 /* PROPS */, ["class"]))
                : _createCommentVNode("v-if", true)
            ])
          ], 10 /* CLASS, PROPS */, _hoisted_4)
        ]),
        "no-options": _withCtx(({ search, searching }) => [
          (_ctx.emptyText)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.emptyText), 1 /* TEXT */))
            : _renderSlot(_ctx.$slots, "no-options", {
                key: 1,
                search: search,
                searching: searching
              })
        ]),
        "selected-option": _withCtx((option) => [
          _renderSlot(_ctx.$slots, "selected-option", { option: option })
        ]),
        tag: _withCtx(({ option, handleTagRemove }: any) => [
          _createElementVNode("span", _hoisted_7, [
            (_ctx.$slots.tag)
              ? _renderSlot(_ctx.$slots, "tag", {
                  key: 0,
                  option: option
                })
              : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(option[_ctx.label]), 1 /* TEXT */)),
            _createElementVNode("span", {
              class: "multiselect-tag-remove",
              onClick: (event) => handleTagRemove(option, event)
            }, [
              _createVNode(_component_x_icon, { class: "icon-m" })
            ], 8 /* PROPS */, _hoisted_9)
          ])
        ]),
        multiplelabel: _withCtx((values) => [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.label)
          }, [
            _renderSlot(_ctx.$slots, "multiplelabel", { values: values })
          ], 2 /* CLASS */)
        ]),
        singlelabel: _withCtx((values) => [
          (_ctx.$slots.singlelabel)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style.label)
              }, [
                _renderSlot(_ctx.$slots, "singlelabel", {
                  value: values.value
                })
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true)
        ]),
        beforelist: _withCtx(() => [
          (_ctx.$slots.beforelist)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["text-xs", _ctx.$style.header])
              }, [
                _renderSlot(_ctx.$slots, "beforelist")
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true)
        ]),
        afterlist: _withCtx(() => [
          (_ctx.$slots.afterlist)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style.more)
              }, [
                _renderSlot(_ctx.$slots, "afterlist")
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true)
        ]),
        _: 3 /* FORWARDED */
      }, 16 /* FULL_PROPS */, ["id", "value", "disabled", "value-prop", "can-clear", "clear-on-select", "placeholder", "no-results-text", "no-options-text", "label", "searchable", "options", "open-direction", "class", "classes", "onUpdate:modelValue", "onOpen", "onClose"])
    ], 2 /* CLASS */)
  ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1))
}