import type { DefaultReviewee } from '@/types/settings'
import {
  ScorecardType,
  type ReviewerReviewVisibility,
  type ManagerReviewVisibility,
  type LeadReviewVisibility,
} from '@/types/workspace'

/**
 * Workspace setting defaults that UI should know about
 */
export const defaultSettings = {
  calibrated: false,
  tagsLocked: false,
  threshold: 50,
  selfReviews: true,
  scorecardType: ScorecardType.Static,
  defaultReviewee: 'dominant' as DefaultReviewee,
  calibrationEnabled: false,
  reviewerVisibility: null as ReviewerReviewVisibility | null,
  leadVisibility: null as LeadReviewVisibility | null,
  managerVisibility: null as ManagerReviewVisibility | null,
  copyConversationReviewToCalibrationReview: false,
}

export const applyDefaults = (settings: Record<string, any> | null) => {
  if (settings === null) return defaultSettings

  return Object.fromEntries(
    Object.entries(defaultSettings).map(([key, value]) => [
      key,
      [null, undefined].includes(settings[key]) ? value : settings[key],
    ]),
  ) as typeof defaultSettings
}
