import { computed, ref, watch } from 'vue'
import { session } from '@/composables/useSession'
import { updateUserSetting } from '@/api/user-settings'

export const showPalette = ref(false)

export const showCommandPaletteBubble = computed(() => !session.user.settings.dismissedBubbles?.commandPalette)

watch(showPalette, async (visible) => {
  if (showCommandPaletteBubble.value && visible) {
    const dismissedBubbles = { commandPalette: true }
    await updateUserSetting('dismissedBubbles', dismissedBubbles)
    session.user.settings.dismissedBubbles = dismissedBubbles
  }
})
