import { type AsyncComponentLoader, defineAsyncComponent } from 'vue'
import { sample } from 'lodash-es'

const logos: Record<string, AsyncComponentLoader[]> = {
  standard: [defineAsyncComponent(() => import('./assets/logos/logo-main.svg'))],
  construction: [defineAsyncComponent(() => import('./assets/logos/logo-circle-helmet.svg'))],
  xmas: [defineAsyncComponent(() => import('./assets/logos/logo-antlers.svg'))],
  halloween: [
    sample([
      defineAsyncComponent(() => import('./assets/logos/logo-count.svg')),
      defineAsyncComponent(() => import('./assets/logos/logo-pumpkin.svg')),
      defineAsyncComponent(() => import('./assets/logos/logo-zombie.svg')),
      defineAsyncComponent(() => import('./assets/logos/logo-mummy.svg')),
    ]),
  ],
  pride: [defineAsyncComponent(() => import('./assets/logos/logo-pride.svg'))],
  ukraine: [defineAsyncComponent(() => import('./assets/logos/logo-ua.svg'))],
}

export default logos
