import config from '@/config'

const ssoRedirectMap = Object.fromEntries(
  config.authSsoRedirects.split(';').map((redir) => {
    const [id, url] = redir.split(/:(.+)/)
    return [id, url]
  }),
)

export const ssoEnabled = (id: string | number) => id in ssoRedirectMap

export const redirectToSso = () => {
  const url = new URL(window.location.href)
  const accountId = parseInt(url.searchParams.get('account') || '0', 10)

  const ssoUrl = ssoRedirectMap[accountId]
  if (ssoUrl) {
    window.location.href = ssoUrl
  } else {
    url.searchParams.delete('account')
    history.replaceState(null, '', url.toString())
  }
}
