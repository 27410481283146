import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

import i18n from '@/i18n'
import { FilterTimePeriod, type FilterTimeParams } from '../types'

dayjs.extend(utc)
dayjs.extend(quarterOfYear)

interface CustomTimePeriodOption {
  name: string
  value: FilterTimePeriod.Custom
}

export interface PresetTimePeriodOption {
  name: string
  value: FilterTimePeriod
  getPeriod: () => { fromDate: string; toDate: string }
}

type TimePeriodOption = PresetTimePeriodOption | CustomTimePeriodOption

/**
 * List of time period presets
 */
export const getTimePeriodOptions = (): TimePeriodOption[] => [
  {
    name: i18n.t('dashboard.time_periods.this_week'),
    value: FilterTimePeriod.ThisWeek,
    getPeriod: () => ({
      fromDate: dayjs().utc().startOf('week').toJSON(),
      toDate: dayjs().utc().endOf('week').toJSON(),
    }),
  },
  {
    name: i18n.t('dashboard.time_periods.last_week'),
    value: FilterTimePeriod.LastWeek,
    getPeriod: () => {
      const lastWeek = dayjs().utc().subtract(1, 'week')
      return {
        fromDate: lastWeek.startOf('week').toJSON(),
        toDate: lastWeek.endOf('week').toJSON(),
      }
    },
  },
  {
    name: i18n.t('dashboard.time_periods.last_7_days'),
    value: FilterTimePeriod.Week,
    getPeriod: () => ({
      fromDate: dayjs().utc().startOf('day').subtract(6, 'day').toJSON(),
      toDate: dayjs().utc().endOf('day').toJSON(),
    }),
  },
  {
    name: i18n.t('dashboard.time_periods.this_month'),
    value: FilterTimePeriod.ThisMonth,
    getPeriod: () => ({
      fromDate: dayjs().utc().startOf('month').toJSON(),
      toDate: dayjs().utc().endOf('month').toJSON(),
    }),
  },
  {
    name: i18n.t('dashboard.time_periods.last_month'),
    value: FilterTimePeriod.LastMonth,
    getPeriod: () => {
      const lastMonth = dayjs().utc().subtract(1, 'month')
      return {
        fromDate: lastMonth.startOf('month').toJSON(),
        toDate: lastMonth.endOf('month').toJSON(),
      }
    },
  },
  {
    name: i18n.t('dashboard.time_periods.last_30_days'),
    value: FilterTimePeriod.Month,
    getPeriod: () => ({
      fromDate: dayjs().utc().startOf('day').subtract(29, 'day').toJSON(),
      toDate: dayjs().utc().endOf('day').toJSON(),
    }),
  },
  {
    name: i18n.t('dashboard.time_periods.custom'),
    value: FilterTimePeriod.Custom,
  },
]

/**
 * Pull dates from timeperiod, also normalize to UTC
 */
export const getDatesFromPeriod = ({ timePeriod, fromDate, toDate }: FilterTimeParams) => {
  const periodOption = getTimePeriodOptions().find((t) => t.value === timePeriod)
  if (!periodOption) throw new Error('Invalid timeperiod')
  return periodOption.value === FilterTimePeriod.Custom ? { fromDate, toDate } : periodOption.getPeriod()
}

export const getTimePeriodLabel = (timePeriod: FilterTimePeriod) => {
  const periodOption = getTimePeriodOptions().find((t) => t.value === timePeriod)
  if (!periodOption) throw new Error('Invalid timeperiod')
  return periodOption.name
}
