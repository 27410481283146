import type { CommentTemplateCreateRequest } from '@klausapp/services/users'

export interface TemplateModalState extends CommentTemplateCreateRequest {
  id?: string
  visible: boolean
}

export enum TemplateType {
  Review = 'review',
  Pin = 'pin',
  Coaching = 'coaching',
}
