import {
  BarChart2Icon,
  CheckSquareIcon,
  FlagIcon,
  HelpCircleIcon,
  MessageSquareIcon,
  SettingsIcon,
  ZapIcon,
} from '../../icons'
import AcademyHatIcon from './assets/icons/academy-hat.svg'

export default {
  dashboard: BarChart2Icon,
  conversations: MessageSquareIcon,
  assignments: CheckSquareIcon,
  activity: ZapIcon,
  coaching: AcademyHatIcon,
  settings: SettingsIcon,
  tasks: CheckSquareIcon,
  users: FlagIcon,
  help: HelpCircleIcon,
  productTray: false,
}
