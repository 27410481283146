import config from '@/config'

const storageKey = 'feature-flag-overrides'

function storedOverrideValues() {
  const overrides: Record<string, boolean> = JSON.parse(sessionStorage.getItem(storageKey) || '{}')
  return overrides
}

/**
 * Write flag overrides to session storage, used in flag manager
 */
export function writeOverrides(values: Record<string, boolean | undefined>) {
  sessionStorage.setItem(storageKey, JSON.stringify(values))
}

/**
 * Apply feature flag overrides if flag manager is enabled
 */
export function applyOverrides<T extends object>(features: T) {
  return config.flagManager ? Object.assign(features, storedOverrideValues()) : features
}
