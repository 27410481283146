import { ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'

import { getCalibrationSessions as apiGetCalibrationSessions, type CalibrationSession } from '@/api/calibration'
import { session } from '@/composables/useSession'
import { getFirstParam } from '@/utils/routes'
import { hasWorkspaceRole, hasAccountRole } from '@/utils/roleUtils'

const defaultSession = {
  title: '',
  dueAt: '',
  createdBy: null,
  createdAt: '',
  updatedBy: null,
  updatedAt: '',
}

export const calibrationSessions = ref<CalibrationSession[]>([])
export const calibrationSessionId = ref<string | undefined>()
export const calibrating = computed(() => !!calibrationSessionId.value)

export default function () {
  const route = useRoute()

  const canSeeCalibrations = computed(() => {
    const hasRequiredRole =
      hasWorkspaceRole('MANAGER', 'LEAD', 'REVIEWER') ||
      (!session.workspace?.role && hasAccountRole('ADMIN', 'MANAGER'))
    const featureEnabled = session.features.calibrationPro
    const settingEnabled = session.workspace.settings.calibrationEnabled

    return hasRequiredRole && featureEnabled && settingEnabled
  })

  watch(
    () => route.params.calibrationSessionId,
    (id) => (calibrationSessionId.value = getFirstParam(id)),
    { immediate: true },
  )

  const getCalibrationSessions = async (): Promise<CalibrationSession[]> => {
    if (!canSeeCalibrations.value) return []

    const { data } = await apiGetCalibrationSessions(session.workspace.id)
    calibrationSessions.value = data
    return calibrationSessions.value
  }

  const calibrationSessionDetails = computed(
    () =>
      calibrationSessions.value.find(({ sessionId }) => sessionId.toString() === calibrationSessionId.value) ||
      defaultSession,
  )

  return {
    canSeeCalibrations,
    calibrationSessions,
    calibrationSessionDetails,
    calibrationSessionId,
    getCalibrationSessions,
  }
}
