import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.session.features.pins)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["text-sm", _ctx.$style.container])
      }, [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('pins.modal.cta.title')), 1 /* TEXT */),
        (_ctx.hasAccountRole('ADMIN'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.buttons)
            }, [
              _createVNode(_component_router_link, { to: { name: 'settings.subscription' } }, {
                default: _withCtx(() => [
                  _createVNode(_component_ui_button, {
                    class: _normalizeClass(_ctx.$style.button)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('pins.modal.cta.button')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["class"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_router_link, {
                class: _normalizeClass([_ctx.$style.link, "content-link"]),
                to: { name: 'settings.subscription' }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('pins.modal.cta.link')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["class"])
            ], 2 /* CLASS */))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}