import type { NormalizedOptions } from 'ky'

import { api, errorHandlerHook } from '@/api'
import type { FeedbackState } from '@/composables/useReviewSidebar'
import type { Dispute } from '../types/dispute'
import { getRequestHeaders } from './index'

export interface DisputeReference {
  connectionId: number
  conversationId: string
  disputeId?: string
}

export interface DisputeCategory {
  id: number
  expectedRating?: number
  note?: string
  rootCause?: string[]
}

export interface DisputePayload extends DisputeReference {
  data: {
    reviewId: string
    categories?: DisputeCategory[]
    comment: Pick<FeedbackState, 'comment' | 'commentTags'>
    assigneeId?: string
  }
}

export const getDisputes = ({ connectionId, conversationId }: DisputeReference): Promise<{ disputes: Dispute[] }> =>
  api
    .extend({ hooks: { afterResponse: undefined } })
    .get('disputes/ticket', {
      searchParams: { ticketId: conversationId, connectionId },
      headers: getRequestHeaders(),
      hooks: {
        afterResponse: [
          (_request: Request, _options: NormalizedOptions, response: Response) => {
            if (response.status === 404) return new Response('{"disputes": []}')
            return response
          },
          errorHandlerHook,
        ],
      },
    })
    .json()

export const disputeReview = ({
  connectionId,
  conversationId: ticketId,
  data,
}: DisputePayload): Promise<{ disputes: Dispute[] }> =>
  api
    .post('disputes', {
      headers: getRequestHeaders(),
      json: { ...data, connectionId, ticketId },
    })
    .json()

export const disputeReviewee = ({
  connectionId,
  conversationId: ticketId,
  data,
}: DisputePayload): Promise<{ disputes: Dispute[] }> =>
  api
    .post('disputes/reviewee', {
      headers: getRequestHeaders(),
      json: { ...data, connectionId, ticketId },
    })
    .json()

export const rejectDispute = ({
  connectionId,
  conversationId: ticketId,
  disputeId,
  data,
}: DisputePayload): Promise<{ disputes: Dispute[] }> =>
  api
    .put(`disputes/${disputeId}/reject`, {
      headers: getRequestHeaders(),
      json: { ...data, connectionId, ticketId },
    })
    .json()

export const approveReviewDispute = ({
  connectionId,
  conversationId: ticketId,
  disputeId,
  data,
}: DisputePayload): Promise<{ disputes: Dispute[] }> =>
  api
    .put(`disputes/${disputeId}/accept`, {
      headers: getRequestHeaders(),
      json: { ...data, connectionId, ticketId },
    })
    .json()

export const approveRevieweeDispute = ({
  connectionId,
  conversationId: ticketId,
  disputeId,
  data,
}: DisputePayload): Promise<{ disputes: Dispute[] }> =>
  api
    .put(`disputes/${disputeId}/reviewee/accept`, {
      headers: getRequestHeaders(),
      json: { ...data, connectionId, ticketId },
    })
    .json()

export const editDispute = ({
  connectionId,
  conversationId: ticketId,
  disputeId,
  data,
}: DisputePayload): Promise<{ disputes: Dispute[] }> =>
  api
    .put(`disputes/${disputeId}`, {
      headers: getRequestHeaders(),
      json: { ...data, connectionId, ticketId },
    })
    .json()

export const removeDispute = ({
  connectionId,
  conversationId,
  disputeId,
}: DisputeReference): Promise<{ disputes: Dispute[] }> =>
  api
    .delete(`disputes/${disputeId}`, {
      searchParams: { connectionId, ticketId: conversationId },
      headers: getRequestHeaders(),
    })
    .json()

export const markAsViewed = (disputeId: string): Promise<{ disputes: Dispute[] }> =>
  api.post(`disputes/${disputeId}/notifications/mark-viewed`, { headers: getRequestHeaders() }).json()
