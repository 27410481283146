import type { Scorecard } from '@/modules/shared/TicketContent/types/scorecard'
import type { StatisticalDistributionPeriod } from '@/modules/conversations/types'
import type { ActivityType } from '@/modules/activity/types'
import type { CatError } from '@/modules/application/types'
import type { MessageRouteParams } from './defineSidebarRoutes'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const emitter = require('tiny-emitter/instance')

interface BusEventMap {
  'universal-error': (value: CatError) => unknown
  'refresh-activities-items': (type: ActivityType) => unknown
  'refresh-activities-count': (value: { id: string; type: ActivityType }) => unknown
  'new-review': (id: string, state?: { hasReview: boolean; youReviewed: boolean }) => unknown
  'change-review-draft': ({ isRemoved }: { isRemoved: boolean }) => unknown
  'remove-review': (id: string, state: { hasReview: boolean; youReviewed: boolean }) => unknown
  'remove-conversation': () => unknown
  'set-ticket-viewed-flag': (id: string) => unknown
  'set-scorecard': (scorecard: Scorecard) => unknown
  'show-connect-helpdesk-modal': () => unknown
  'user-limit-reached': () => unknown
  'ticket-show-loader': () => unknown
  'ticket-hide-loader': () => unknown
  'ticket-list-loaded': () => unknown
  'app-hide-klaus': () => unknown
  'app-show-klaus': () => unknown
  'refresh-coaching-sessions-count': () => unknown
  'refresh-pins-count': () => unknown
  'new-pin-saved': () => unknown
  'refresh-pins-folder-content': () => unknown
  'comment-editor-focus': () => unknown
  'comment-editor-reset': () => unknown
  'comment-editor-toggle-expand': () => unknown
  'stop-editing-ratings': () => unknown
  'collapse-review-footer': () => unknown
  'toggle-review-sidebar': () => unknown
  'groups-count-changed': () => unknown
  'stop-editing-comment': () => unknown
  'highlight-message': (state: { ticketId: string; toHighlight: boolean }) => unknown
  'set-message-id': (params: MessageRouteParams) => unknown
  'search-ticket': (id: string) => unknown
  'show-page-nav': () => unknown
  'show-lightbox': (src: string) => unknown
  'show-command-palette': () => unknown
  'visual-filters-period-changed': (period: StatisticalDistributionPeriod) => unknown
  'dashboard-sidebar-filters-cleared': () => unknown
  'show-categories-type-modal': () => unknown
  'discard-review-draft': () => unknown
}

export type BusEventName = keyof BusEventMap
export type BusEventListener<K extends BusEventName> = BusEventMap[K]

const bus = {
  $on: <K extends BusEventName>(key: K, listener: BusEventListener<K>) => emitter.on(key, listener),
  $once: <K extends BusEventName>(key: K, listener: BusEventListener<K>) => emitter.once(key, listener),
  $off: <K extends BusEventName>(key: K, listener: BusEventListener<K>) => emitter.off(key, listener),
  $emit: <K extends BusEventName>(key: K, ...payload: Parameters<BusEventListener<K>>) => emitter.emit(key, ...payload),
}

export { bus }
