import type { AccountRole, WorkspaceRole } from '@/data/roles'
import { session } from '@/composables/useSession'

export const hasAccountRole = (...roles: AccountRole[]) => {
  return !!roles?.includes(session.account?.role)
}

export const hasWorkspaceRole = (...roles: WorkspaceRole[]) => {
  return session.workspace?.role && !!roles?.includes(session.workspace?.role)
}

export const hasHighestWorkspaceRole = (...roles: WorkspaceRole[]) => {
  return !!roles?.includes(session.workspace?.highestRole)
}

export const isWorkspaceRoleIncluded = (role: WorkspaceRole | undefined, roles: WorkspaceRole[]) => {
  return role && !!roles?.includes(role)
}
