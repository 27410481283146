import { computed, ref } from 'vue'
import { PinReference_Type } from '@klausapp/services/pins'

export interface PinModalState {
  visible: boolean
  pinId: string
  referenceType: PinReference_Type
  referenceValue: string
  folderUserId: string
  body: string
  dashboardCard: boolean
}

const defaultState: PinModalState = {
  visible: false,
  pinId: '',
  referenceType: PinReference_Type.MANUAL,
  referenceValue: '',
  folderUserId: '',
  body: '',
  dashboardCard: false,
}

const state = ref({ ...defaultState })

function openPinModal(newState) {
  state.value = {
    ...defaultState,
    visible: true,
    ...newState,
  }
}

function closePinModal() {
  state.value = { ...defaultState }
}

const pinModalData = computed(() => state.value)

export { openPinModal, closePinModal, pinModalData }
