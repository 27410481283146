import { defineAsyncComponent } from 'vue'
import Loading from './EditorLoader.vue'
import Error from './EditorError.vue'

export default defineAsyncComponent({
  loader: () => import('./CommentEditor.vue'),
  loadingComponent: Loading,
  errorComponent: Error,
  delay: 200,
  timeout: 60000,
})
