export default `
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@%  /@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@( ., %@@@@@@
@@@@#.**, .&@@@@@@@@@@@@@@@@@@@@@@@@@@@@@/ ,*****.&@@@@@
@@@@%,*****  *@@@@@@@@@@@@@@@@@@@@@@@@@/ ,*******,/@@@@@
@@@@@,*******.  &@@@@@@@@@@@@@@@@@@@@&   .,*******(@@@@@
@@@@@%*******,    *&@@@@@@@@@@@@@@&*     .********&@@@@@
@@@@@@(*****                            ,********/@@@@@@
@@@@@@@/*****,                          ,********&@@@@@@
@@@@@@@,,***                               .*****@@@@@@@
@@@@@@@@                                    .*. %@@@@@@@
@@@@@@@@. .*//((//,.          .*//((/*,.        /@@@@@@@
@@@@@@@@ @%,.     ./%@@@@@@@@#*......./&@@@*    (@@@@@@@
@@@@@@@ @*  ./@&/&%  (@@&&@&. *@( ,%@/. /@%     @@@@@@@@
@@@@@@  @#   %&  ,@% &&.  *@% (@%  &@*  %@,     @@@@@@@@
@@@@@@  #@*   ,#%#,.&&,    ,@%. .,.    (@(      #@@@@@@@
@@@@@@@, .%@&&&&&&@&. ,****, ,&@&&&&&@@#.       /@@@@@@@
@@@@@@@@,               *,                     %@@@@@@@@
@@@@@@@@@#               .                   ,@@@@@@@@@@
@@@@@@@@@@@@,                             (@@@@@@@@@@@@@
@@@@@@@@@@@@@@@%/                   *#@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@`
