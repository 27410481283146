const MAX_INITIALS = 2

export function getInitials(username: string) {
  const parts = username.split(/[ -]/)
  let initials = parts.map((part) => part[0]).join('')

  if (initials.length > MAX_INITIALS && initials.search(/[A-Z]/) !== -1) {
    initials = initials.replace(/[a-z]+/g, '')
  }

  initials = initials.slice(0, MAX_INITIALS).toUpperCase()

  return initials
}
