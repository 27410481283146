import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import i18n from '@/i18n'

dayjs.extend(duration)

export const formatDuration = (notRoundedSeconds: number | null | undefined, short?: boolean) => {
  if (notRoundedSeconds === null || notRoundedSeconds === undefined || Number.isNaN(notRoundedSeconds))
    return notRoundedSeconds

  const seconds = Math.round(notRoundedSeconds % 60)

  if (notRoundedSeconds < 60) return i18n.t('universal.duration.seconds_later', { seconds })

  const totalHours = Math.floor(notRoundedSeconds / (60 * 60))
  const days = Math.floor(totalHours / 24)
  const hours = totalHours % 24
  const minutes = Math.floor(notRoundedSeconds / 60) % 60

  if (days > 0) {
    if (hours === 0 && minutes === 0) return i18n.t('universal.duration.days', { days })
    if (minutes === 0) return i18n.t('universal.duration.days_hours', { days, hours })
    if (hours === 0) return i18n.t('universal.duration.days_minutes', { days, minutes })
    if (short) return i18n.t('universal.duration.days_hours', { days, hours })

    return i18n.t('universal.duration.days_hours_minutes', { days, hours, minutes })
  }

  if (hours > 0) {
    if (minutes === 0 && seconds === 0) return i18n.t('universal.duration.hours_later', { hours })
    if (minutes > 0 && seconds !== 0)
      return i18n.t('universal.duration.hours_minutes_seconds_later', { hours, minutes, seconds })
    if (minutes > 0) return i18n.t('universal.duration.hours_minutes_later', { hours, minutes })

    return i18n.t('universal.duration.hours_seconds_later', { hours, seconds })
  }

  if (seconds === 0) return i18n.t('universal.duration.minutes_later', { minutes })

  return i18n.t('universal.duration.minutes_seconds_later', { minutes, seconds })
}

export const formatReplyDuration = (
  fromTime: dayjs.Dayjs | null | undefined,
  toTime: dayjs.Dayjs | null | undefined,
) => {
  if (!fromTime || !toTime) return null

  const duration = dayjs.duration(toTime.diff(fromTime, 's'), 's')
  const seconds = duration.get('seconds')

  if (duration.get('years') >= 1) return i18n.t('universal.duration.years_later', { years: duration.get('years') })
  if (duration.get('months') >= 1) return i18n.t('universal.duration.months_later', { months: duration.get('months') })
  if (duration.get('days') >= 1) return i18n.t('universal.duration.days_later', { days: duration.get('days') })
  if (duration.get('hours') >= 1) return i18n.t('universal.duration.hours_later', { hours: duration.get('hours') })
  if (duration.get('minutes') < 1) return i18n.t('universal.duration.seconds_later', { seconds })
  if (seconds === 0) return i18n.t('universal.duration.minutes_later', { minutes: duration.get('minutes') })

  return i18n.t('universal.duration.minutes_seconds_later', { minutes: duration.get('minutes'), seconds })
}
