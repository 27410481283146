import { MESSAGE_PREFIX } from '@klausapp/extension/src/utils/messaging'
export { MESSAGE_PREFIX } from '@klausapp/extension/src/utils/messaging'

export const sendMessage = (message: string, data?: any) => {
  top?.window.postMessage({ type: `${MESSAGE_PREFIX}${message}`, data }, '*')
}

export const shareHostsWithExtension = (hosts?: string[]) => {
  sendMessage('appLoaded', { authenticated: !!hosts, hosts })
}
