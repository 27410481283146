const positiveScale = ['green-10', 'green-20', 'green-30']
const negativeScale = ['red-10', 'red-20', 'red-30']

export const getColorByThreshold = (value: number, threshold: number) => {
  if (typeof value !== 'number') return 'gray-20'

  const isPositive = value >= threshold

  let colorIndex = ((threshold - value) / threshold) * (negativeScale.length - 1)
  if (isPositive) {
    colorIndex = value - threshold === 0 ? 0 : ((value - threshold) / (100 - threshold)) * (positiveScale.length - 1)
  }

  const roundedIndex = Math.min(Math.round(colorIndex), 2)
  return isPositive ? positiveScale[roundedIndex] : negativeScale[roundedIndex]
}
