import { computed, type Ref } from 'vue'
import { createEventHook } from '@vueuse/core'

import type { PropsWithDefaults, UpdateParams, Modes } from '../../types'
import { ALL_ID } from '../../constants'
import { getId } from '../utils'
import useSortedList from '../common/useSortedList'
import useSelectedList from '../common/useSelectedList'
import useFlatOptions from '../common/useFlatOptions'

export default function useMultipleIntermediaryList<
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
>(props: PropsWithDefaults<T, P, L, M, V, O>, _emit: any, searchQuery: Ref<string>) {
  const updateResults = createEventHook<UpdateParams<T, P, M, O>>()

  const { reorderList, sortedOptions, sortedGroups, firstOption } = useSortedList(props)
  const { selectedValues, excludedValues, updateModelValue, onUpdate } = useSelectedList<T, P, L, M, V, O>(props)
  const { flatOptions } = useFlatOptions(props)

  onUpdate(updateResults.trigger)

  const isAllSelected = computed(() => !props.modelValue.length)

  const isSelected = (option: T) => {
    if (showAllGroup.value && isAllSelected.value) {
      return (
        !excludedValues.value.includes(option[props.propToCheck]) ||
        selectedValues.value.includes(option[props.propToCheck]) ||
        option[props.propToCheck] === ALL_ID
      )
    }

    const ids = props.modelValue.map((item: T | T[P]) => getId(item, props))

    return ids.includes(option[props.propToCheck])
  }

  const isIndeterminate = (option: T) =>
    !!(!props.modelValue.length && excludedValues.value?.length && option[props.propToCheck] === ALL_ID)

  const selectAllOptions = () => {
    selectedValues.value = []
  }

  const selectFirstOption = () => {
    if (!firstOption.value) return

    selectedValues.value = [firstOption.value[props.propToCheck]]
  }

  const toggleAll = () => {
    if (!isAllSelected.value) {
      selectAllOptions()
    } else {
      selectFirstOption()
    }
    excludedValues.value = []
  }

  const deselectOption = (option: T) => {
    const currentValue = option[props.propToCheck]
    selectedValues.value = selectedValues.value.filter((value) => value !== currentValue)

    if (isAllSelected.value) {
      excludedValues.value = excludedValues.value.concat(currentValue)
    }
    // Check if all options have been manually deselected with "All selected" option still active
    if (excludedValues.value.length === flatOptions.value.length && isAllSelected.value) {
      toggleAll()
    }
  }

  const selectOption = (option: T) => {
    const currentValue = option[props.propToCheck]
    const isExcluded = !!excludedValues.value.find((value) => value === currentValue)
    if (isExcluded) {
      excludedValues.value = excludedValues.value.filter((value) => value !== currentValue)
    } else {
      selectedValues.value = selectedValues.value.concat(currentValue)
    }
  }

  const toggle = (option: T) => {
    if (option[props.propToCheck] === ALL_ID) {
      toggleAll()
    } else if (isSelected(option)) {
      deselectOption(option)
    } else {
      selectOption(option)
    }

    updateModelValue(selectedValues.value, excludedValues.value)
  }

  const selectedIds = computed(() => {
    return isAllSelected.value ? [ALL_ID, props.options] : selectedValues.value
  })

  const showAllGroup = computed(() => !searchQuery.value && !!props.selectAllLabel && !!flatOptions.value.length)

  return {
    excludedValues,
    isSelected,
    isIndeterminate,
    reorderList,
    selectedIds,
    sortedOptions,
    sortedGroups,
    showAllGroup,
    toggle,
    addOrSelectOption: (_query: string) => undefined,
    onUpdate: updateResults.on,
  }
}
