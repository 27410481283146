import type DOMPurify from 'dompurify'
import type { DirectiveBinding } from 'vue'
import { SANITIZER_PRESETS } from '../constants/sanitize-config'
import { sanitize } from '../sanitize'

const MODIFIERS: Record<string, DOMPurify.Config> = {
  allowLinks: SANITIZER_PRESETS.LINKS_ONLY,
  allowInlineStyles: SANITIZER_PRESETS.ALLOW_INLINE_STYLES,
  allowLineBreaks: SANITIZER_PRESETS.ALLOW_LINE_BREAKS,
}

export function sanitizeHook(
  element: HTMLElement,
  { oldValue: oldHtml, value: html, modifiers }: DirectiveBinding<string>,
) {
  if (oldHtml === html) return

  const modifierConfig = MODIFIERS[Object.keys(modifiers)[0]]
  const config = modifierConfig ?? SANITIZER_PRESETS.BASE_CONFIG

  element.innerHTML = sanitize(html, config)
}
