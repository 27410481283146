import * as Sentry from '@sentry/vue'

import i18n from '@/i18n'
import { toast } from '@/components/toast'

export const copy = async (text: string, message?: string) => {
  try {
    await navigator.clipboard.writeText(text)
    toast({
      status: 'success',
      message: message || i18n.t('universal.copy_success'),
    })
  } catch (err) {
    Sentry.captureException(err)
  }
}
