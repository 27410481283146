export const formatCount = (count: number) => {
  if (count <= 999) return count
  // toFixed uses a large number to prevent the automatic rounding from affecting us
  if (count <= 9999)
    return (
      Number(count / 1000)
        .toFixed(10)
        .slice(0, -9) + 'k'
    )

  return Math.floor(count / 1000) + 'k'
}
