export const defaultTeamColors = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#9E9E9E',
  '#607D8B',
]

const positiveScale = ['green-10', 'green-20', 'green-30']
const negativeScale = ['red-10', 'red-20', 'red-30']

export const getColorByThreshold = (value: number, threshold: number) => {
  if (typeof value !== 'number') return 'gray-20'

  const isPositive = value >= threshold

  let colorIndex = ((threshold - value) / threshold) * (negativeScale.length - 1)
  if (isPositive) {
    colorIndex = value - threshold === 0 ? 0 : ((value - threshold) / (100 - threshold)) * (positiveScale.length - 1)
  }

  const roundedIndex = Math.round(colorIndex)
  return isPositive ? positiveScale[roundedIndex] : negativeScale[roundedIndex]
}
