export enum SortValues {
  NEWEST = '-createdAt',
  OLDEST = 'createdAt',
  RANDOM = 'random',
  UPDATED = '-updatedAt',
  RECENT_REPLIED = '-repliedAt',
  MOST_REPLIES = '-replyCount',
  LEAST_REPLIES = 'replyCount',
}

export const analyticsLabels: Record<SortValues, string> = {
  [SortValues.NEWEST]: 'timeDesc',
  [SortValues.OLDEST]: 'timeAsc',
  [SortValues.RANDOM]: 'random',
  [SortValues.UPDATED]: 'updatedDesc',
  [SortValues.RECENT_REPLIED]: 'repliedDesc',
  [SortValues.MOST_REPLIES]: 'repliesDesc',
  [SortValues.LEAST_REPLIES]: 'repliesAsc',
}

export const sortOptions = {
  'conversations.ticket_list.sort_newest': SortValues.NEWEST,
  'conversations.ticket_list.sort_oldest': SortValues.OLDEST,
  'conversations.ticket_list.sort_random': SortValues.RANDOM,
}

export const assignmentSortOptions: Record<string, SortValues> = {
  'conversations.ticket_list.sort_newest': SortValues.NEWEST,
  'conversations.ticket_list.sort_oldest': SortValues.OLDEST,
  'conversations.ticket_list.sort_recent': SortValues.UPDATED,
  'conversations.ticket_list.sort_recent_reply': SortValues.RECENT_REPLIED,
  'conversations.ticket_list.sort_most_replies': SortValues.MOST_REPLIES,
  'conversations.ticket_list.sort_least_replies': SortValues.LEAST_REPLIES,
  'conversations.ticket_list.sort_random': SortValues.RANDOM,
}
