import { computed, type Ref } from 'vue'
import { createEventHook } from '@vueuse/core'

import type { PropsWithDefaults, UpdateParams, Modes } from '../../types'
import { ALL_ID } from '../../constants'

import { getId, getIds } from '../utils'
import useSortedList from '../common/useSortedList'
import useSelectedList from '../common/useSelectedList'
import useFlatOptions from '../common/useFlatOptions'

export default function useMultipleNoneList<
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
>(props: PropsWithDefaults<T, P, L, M, V, O>, _emit: any, searchQuery: Ref<string>) {
  const updateResults = createEventHook<UpdateParams<T, P, M, O>>()

  const { reorderList, sortedOptions, sortedGroups, firstOption } = useSortedList(props)
  const { selectedValues, excludedValues, updateModelValue, onUpdate } = useSelectedList<T, P, L, M, V, O>(props)
  const { flatOptions } = useFlatOptions(props)

  onUpdate(updateResults.trigger)

  const isSelected = (option: T) => {
    if (!props.modelValue.length || (showAllGroup.value && allSelected.value)) {
      return option[props.propToCheck] === ALL_ID
    }

    return selectedIds.value.includes(option[props.propToCheck])
  }

  const selectFirst = () => {
    if (!firstOption.value) return

    selectedValues.value = [firstOption.value[props.propToCheck]]
  }

  const selectAll = () => {
    selectedValues.value = []
  }

  const toggleAll = () => (allSelected.value ? selectFirst() : selectAll())

  const toggle = (option: T) => {
    if (option[props.propToCheck] === ALL_ID) {
      toggleAll()
    } else if (allSelected.value) {
      selectedValues.value = [option[props.propToCheck]]
    } else if (isSelected(option)) {
      selectedValues.value = getIds<T, P, L, M, V, O>(props.modelValue, props).filter(
        (id) => id !== option[props.propToCheck],
      )
    } else {
      selectedValues.value = getIds<T, P, L, M, V, O>(props.modelValue, props).concat(option[props.propToCheck])
    }

    updateModelValue(selectedValues.value, [])
  }

  const allSelected = computed(() => props.modelValue.length === 0)

  const selectedIds = computed(() => props.modelValue.map((item: T | T[P]) => getId(item, props)))

  const simplifiedSelectedIds = computed(() => (allSelected.value ? [ALL_ID] : selectedIds.value))

  const showAllGroup = computed(() => !searchQuery.value && !!props.selectAllLabel && !!flatOptions.value.length)

  return {
    excludedValues,
    isSelected,
    isIndeterminate: (_option: T) => false,
    selectedIds: simplifiedSelectedIds,
    sortedOptions,
    sortedGroups,
    reorderList,
    showAllGroup,
    toggle,
    addOrSelectOption: (_query: string) => undefined,
    onUpdate: updateResults.on,
  }
}
