import { computed } from 'vue'
import i18n from '@/i18n'

export enum DataRetentionOption {
  OneMonth = 'MONTHS_1',
  ThreeMonths = 'MONTHS_3',
  SixMonths = 'MONTHS_6',
  OneYear = 'MONTHS_12',
}

export const dataRetentionOptions = computed(() => [
  { name: i18n.t('settings.connections.data_retention_period.months_1'), id: DataRetentionOption.OneMonth },
  { name: i18n.t('settings.connections.data_retention_period.months_3'), id: DataRetentionOption.ThreeMonths },
  { name: i18n.t('settings.connections.data_retention_period.months_6'), id: DataRetentionOption.SixMonths },
  { name: i18n.t('settings.connections.data_retention_period.months_12'), id: DataRetentionOption.OneYear },
])
