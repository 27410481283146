import { computed, watch, reactive, type ComputedRef } from 'vue'
import { orderBy, uniqBy } from 'lodash-es'

import type {
  GetSurveysResponse_Type as TicketSurvey,
  GetSurveysResponse_Response as TicketSurveyResponse,
} from '@klausapp/services/tickets'
import type { Bot } from '@klausapp/services/users'
import type { AutoQaReviewForConversationResponse } from '@klausapp/services/autoqa'
import i18n from '@/i18n'
import { session } from '@/composables/useSession'
import { updateUserSetting } from '@/api/user-settings'
import { getCategories } from '@/modules/workspace/api/categories'
import type { Scorecard } from '@/modules/shared/TicketContent/types/scorecard'
import type { User as ManagementUser } from '@/modules/user-management/types'
import { isDynamicScorecard } from '@/modules/shared/TicketContent/utils/scorecard'
import { type Category, type CategoryGroup, type Rating, isRated } from '@/modules/shared/Review/utils'
import type { CommentTag, Feedback } from '@/modules/shared/TicketContent/types/feedback'
import { getLoadingRevieweeOption } from '@/modules/shared/TicketContent/utils/defaultReviewee'
import {
  getFeedback,
  getScorecards,
  getInternalCommentTags,
  getAutoQaConversationReviews,
} from '@/modules/shared/TicketContent/api'
import { getCalibrationFeedback } from '@/modules/shared/TicketContent/api/calibration'
import type { TicketData, ExternalCommentExt } from '@/modules/conversations/types'
import {
  disputeState,
  resetDispute,
  loadDisputes,
  disputeTitle,
  selectDisputeById,
} from '@/modules/shared/TicketContent/composables/useDispute'
import { calibrationSessionId } from '@/modules/shared/TicketContent/composables/useCalibrationSession'
import { hasAccountRole, hasWorkspaceRole } from '@/utils/roleUtils'
import timeTracking from '@/modules/time-tracking/timeTracking'
import type { StopParams } from '@/modules/time-tracking/types'
import { DisplayType } from '@/data/scales'
import { searchUsers } from '@/modules/dashboard/api'
import { getTicketSurveys } from '@/modules/shared/TicketContent/components/csat/api'
import isBot from '@/modules/shared/Review/utils/isBot'

export interface User {
  id?: number
  name: string
  avatar?: string
  email?: string
  selfDisabled?: boolean
}

export interface FeedbackState {
  user: User | Bot
  ratings: Record<number, Rating>
  comment: string
  commentTags?: CommentTag[]
  scorecardId?: string | null
}

export interface MessagePreview {
  label: string
  type: 'message' | 'call' | 'attachment'
}

export interface Survey extends TicketSurveyResponse {
  surveyName: TicketSurvey['name']
  averageScore: TicketSurvey['averageScore']
}

export interface PusherUser extends ManagementUser {
  isSpy: 'true' | 'false'
}

interface State {
  isFooterCollapsed?: boolean
  connectionId?: number
  conversationId?: string
  selectedMessageId?: string
  selectedTranscriptionId?: string
  selectedTranscriptionMessageId?: string
  disputeId?: string
  scorecards: Scorecard[]
  selectedScorecard?: Scorecard
  selectedUser: User | Bot
  settings: {
    unbiasedGrading: boolean
    threshold: number
    selfReviews: boolean
    internalTagsLocked: boolean
  }
  // Sent to BE for tracking via the POST /reviews endpoint
  metadata: {
    assignmentId?: string
    sort?: string
    reviewedViaVisualFilters: boolean
    translatedConversation?: boolean
  }
  categories: Category[]
  workspaceUsers: User[]
  workspaceUsersLoading: boolean
  feedbackList: Feedback[]
  feedbackScore: number
  autoQaFeedbackScore: number
  internalTags: string[]
  loadingFeedback: boolean
  defaultReviewee?: number | string
  assignmentRevieweeIds?: number[]
  replacingConversation: boolean
  removingConversation: boolean
  ticket?: TicketData
  ticketContentNotFound: boolean
  autoQaReviews?: AutoQaReviewForConversationResponse
  surveys?: Survey[]
  usersReviewing: PusherUser[]
  usersSpying: PusherUser[]
}

const SCORECARD_STORAGE_KEY = 'klausSelectedScorecard.v2'

export const generateScorecardStorageKey = (workspaceId: number) => `${workspaceId}.${SCORECARD_STORAGE_KEY}`

const defaultState: State = {
  isFooterCollapsed: true,
  connectionId: undefined,
  conversationId: undefined,
  selectedMessageId: undefined,
  selectedTranscriptionId: undefined,
  selectedTranscriptionMessageId: undefined,
  disputeId: undefined,
  scorecards: [],
  selectedScorecard: undefined,
  selectedUser: getLoadingRevieweeOption(),
  settings: {
    unbiasedGrading: false,
    threshold: 50,
    selfReviews: false,
    internalTagsLocked: true,
  },
  metadata: {
    sort: undefined,
    assignmentId: undefined,
    reviewedViaVisualFilters: false,
    translatedConversation: false,
  },
  categories: [],
  workspaceUsers: [],
  workspaceUsersLoading: false,
  feedbackList: [],
  feedbackScore: undefined,
  autoQaFeedbackScore: undefined,
  internalTags: [],
  loadingFeedback: false,
  defaultReviewee: undefined,
  assignmentRevieweeIds: [],
  replacingConversation: false,
  removingConversation: false,
  ticket: undefined,
  ticketContentNotFound: false,
  autoQaReviews: undefined,
  surveys: [],
  usersReviewing: [],
  usersSpying: [],
}

export const sidebarState = reactive(defaultState)
export const sectionStates = reactive({
  reviews: false,
  insights: false,
  surveys: false,
  helpdesk: false,
})

export const allowSkip = computed(() => session.account.ratingCategory.skippable)
export const displayAsEmoji = computed(() => session.account.ratingCategory.displayType === DisplayType.Emotes)

export const validReviews = computed(() =>
  sidebarState.feedbackList.filter(
    (feedback) => feedback.ratings.length && !feedback.deleted && feedback.ratings.some(isRated),
  ),
)

// Scorecards
export function setScorecards(scorecards: Scorecard[]) {
  sidebarState.scorecards = scorecards
}

export function setScorecard(selectedScorecard: Scorecard) {
  sidebarState.selectedScorecard = selectedScorecard
}

export const setDefaultScorecard = (workspaceId: number) => {
  const key = generateScorecardStorageKey(workspaceId)
  const persisted: string | undefined = localStorage[key]
  const defaultScorecardId = persisted || sidebarState.selectedScorecard?.id

  if (defaultScorecardId) {
    const scorecard = sidebarState.scorecards.find((sc) => sc.id === defaultScorecardId)

    // Outdated scorecard persisted, remove it
    if (!scorecard) localStorage.removeItem(key)
    setScorecard(scorecard || sidebarState.scorecards[0])
  } else {
    setScorecard(sidebarState.scorecards[0])
  }
}

export const activeScorecardCategories = computed(() => sidebarState.selectedScorecard?.ratingCategoryIds || [])

export const emptyDynamicScorecard = computed(() => {
  const scorecard = sidebarState.selectedScorecard
  return isDynamicScorecard(scorecard) && !scorecard?.ratingCategoryIds?.length
})

export const categoryOptions = computed<Record<number, Category>>(() =>
  sidebarState.categories.reduce((mappedCategories, cat) => {
    mappedCategories[cat.categoryId] = cat
    return mappedCategories
  }, {}),
)

export const categoryGroups = computed(() => {
  return uniqBy(Object.values(categoryOptions.value), 'groupId')
    .map((cat) => ({
      id: cat.groupId,
      name: cat.groupName,
      position: cat.groupPosition,
    }))
    .filter((g): g is CategoryGroup => !!g.id)
    .sort((a, b) => a.position - b.position)
})

/**
 * Feedback can get filtered
 * 1. for a specific message
 * 2. that only belongs to the logged in user (agent)
 * 3. if in a unbiased grading workspace
 * 4. when calibrating reviews
 * otherwise all feedback for the conversation is displayed
 */
export const isFeedbackFiltered = computed(() => {
  const userHasPostedFeedback = sidebarState.feedbackList.some(
    (feedback) => !feedback.deleted && feedback.reviewerId === session.user.id.toString(),
  )
  return sidebarState.settings.unbiasedGrading && !userHasPostedFeedback
})

export const visibleManualFeedback = computed(() => {
  const { selectedMessageId, selectedTranscriptionMessageId, feedbackList } = sidebarState

  if (isFeedbackFiltered.value) {
    const userId = session.user.id.toString()
    return feedbackList.filter(
      (f) =>
        [f.revieweeId, f.reviewerId, ...f.tags.map((tag) => tag.userId)].includes(userId) &&
        (!selectedMessageId || selectedMessageId === f.messageId),
    )
  }

  if (selectedTranscriptionMessageId) {
    return feedbackList.filter((f) => f.transcriptionMessageId === selectedTranscriptionMessageId)
  } else if (selectedMessageId) {
    return feedbackList.filter((f) => f.messageId === selectedMessageId)
  }

  return feedbackList
})

export const visibleAutoQaFeedback = computed(() => {
  if (calibrationSessionId.value) return []
  const { selectedMessageId, selectedTranscriptionMessageId } = sidebarState

  if (isFeedbackFiltered.value || selectedTranscriptionMessageId || selectedMessageId) return []

  return orderBy(
    Object.values(sidebarState.autoQaReviews?.reviewPerUser ?? {})
      .map((r) => r.feedback)
      .filter((f) => !!f),
    'revieweeName',
  )
})

export const ticketReviews = computed(() => sidebarState?.feedbackList?.filter(feedbackIsExistingReview) ?? [])

export const reviewsByLoggedInUser = computed(() => ticketReviews.value.filter(feedbackReviewerIsLoggedInUser))

/**
 * Users who have received reviews (not deleted feedback that has ratings) from the logged in user.
 * Recipients should be deduced from the reviews of the currently selected target (e.g. a single message),
 * or all reviews if no message is selected.
 */
export const reviewRecipients = computed(() =>
  visibleManualFeedback.value
    .filter(feedbackIsExistingReview)
    .filter(feedbackReviewerIsLoggedInUser)
    .filter(feedbackTargetsSelectedInteraction)
    .map((r) => r.revieweeId),
)

function feedbackIsExistingReview(feedback: Feedback) {
  return !feedback.deleted && !!feedback.ratings?.length
}

function feedbackReviewerIsLoggedInUser(feedback: Feedback) {
  return feedback.reviewerId === session.user.id.toString()
}

function feedbackTargetsSelectedInteraction(feedback: Feedback) {
  const { selectedMessageId, selectedTranscriptionId, selectedTranscriptionMessageId } = sidebarState

  if (selectedTranscriptionMessageId) {
    return feedback.transcriptionMessageId === selectedTranscriptionMessageId
  }

  if (selectedTranscriptionId) {
    return !feedback.transcriptionMessageId && feedback.transcriptionId === selectedTranscriptionId
  }

  if (selectedMessageId) {
    return feedback.messageId === selectedMessageId
  }

  return !feedback.messageId
}

export const reviewSectionTitle = computed(() => {
  if (disputeState.disputing) return disputeTitle.value
  if (sidebarState.removingConversation) return i18n.t('tasks.remove.title')
  if (sidebarState.replacingConversation) return i18n.t('tasks.replace.title')

  const user = sidebarState.selectedUser
  if (
    (!isBot(user) && user.selfDisabled) ||
    (user.id && reviewRecipients.value.includes(String(user.id))) ||
    (hasWorkspaceRole('AGENT') && user.id !== session.user.id)
  ) {
    return i18n.t('conversations.sidebar.review_title_comment')
  }

  if (sidebarState.selectedMessageId) return i18n.t('conversations.sidebar.review_title_message')

  return i18n.t('conversations.sidebar.review_title_ticket')
})

export const reviewTarget: ComputedRef<TicketData | ExternalCommentExt | undefined> = computed(() => {
  if (!sidebarState.selectedMessageId) return sidebarState.ticket

  const message = sidebarState.ticket?.externalComments?.find((c) => c.externalId === sidebarState.selectedMessageId)
  return message || sidebarState.ticket
})

// Load conversation-specific or dynamic data
watch(
  () => [sidebarState.conversationId, sidebarState.connectionId, calibrationSessionId.value],
  () => loadState(),
)

watch(
  () => sidebarState.disputeId,
  async (disputeId) => {
    if (!session.features.disputes || !disputeId) return

    resetDispute()
    if (disputeState.disputesList.length) return selectDisputeById(disputeId)

    if (sidebarState.connectionId && sidebarState.conversationId) {
      await loadDisputes(sidebarState.connectionId, sidebarState.conversationId)
      selectDisputeById(disputeId)
    }
  },
  { immediate: true },
)

// Persist sectionStates to user settings
watch(sectionStates, () => {
  updateUserSetting('reviewSidebar', {
    ...session.user.settings.reviewSidebar,
    sections: sectionStates,
  })
})

watch(() => sidebarState.feedbackList, updateScore, { deep: true })
watch(visibleAutoQaFeedback, updateAutoQaFeedbackScore, { deep: true })

// Load data once that does not change often
export function setup() {
  resetState()
  if (!sidebarState.workspaceUsers.length) initWorkspaceUserList()

  const settings = session.workspace.settings
  sidebarState.settings.internalTagsLocked = settings.tagsLocked
  sidebarState.settings.threshold = settings.threshold
  sidebarState.settings.unbiasedGrading =
    hasAccountRole('USER') && hasWorkspaceRole('REVIEWER', 'AGENT') && settings.calibrated
  sidebarState.settings.selfReviews = settings.selfReviews
  sidebarState.defaultReviewee = undefined

  loadRatingCategories()
  loadInternalTags()
  loadState()

  if (session.user.settings.reviewSidebar && 'sections' in session.user.settings.reviewSidebar) {
    Object.assign(sectionStates, session.user.settings.reviewSidebar.sections)
  }
}

function resetState() {
  sidebarState.selectedMessageId = undefined
  sidebarState.scorecards = []
  sidebarState.feedbackList = []
  sidebarState.autoQaReviews = undefined
  sidebarState.surveys = []
  disputeState.disputesList = []
}

function loadState() {
  resetState()
  loadScorecards()
  loadFeedback()
  loadTicketSurveys()
  loadAutoQa()
  loadInternalTags()

  if (session.features.disputes) {
    resetDispute()
    if (sidebarState.connectionId && sidebarState.conversationId)
      loadDisputes(sidebarState.connectionId, sidebarState.conversationId)
  }
}

export function resetMetadata() {
  sidebarState.metadata.sort = undefined
  sidebarState.metadata.assignmentId = undefined
  sidebarState.metadata.reviewedViaVisualFilters = false
  sidebarState.metadata.translatedConversation = false
}

export function resetUsersList() {
  sidebarState.workspaceUsers = []
}

export function resetConversation() {
  sidebarState.connectionId = undefined
  sidebarState.conversationId = undefined
}

export async function loadScorecards() {
  if (!sidebarState.conversationId || !sidebarState.connectionId) return

  const { scorecards } = await getScorecards(sidebarState.conversationId, sidebarState.connectionId)
  const mapped = scorecards.map((card) => ({
    ...card,
    ratingCategoryIds: (card.ratingCategoryIds as unknown[] as string[]).map(parseFloat),
  }))
  setScorecards(mapped)

  if (mapped[0] && (!sidebarState.selectedScorecard || isDynamicScorecard)) {
    setDefaultScorecard(session.workspace.id)
  }
}

export async function loadInternalTags() {
  const { data } = await getInternalCommentTags()
  sidebarState.internalTags = data
}

export async function loadRatingCategories() {
  const { data } = await getCategories(session.workspace.id)

  sidebarState.categories = data.map((cat) => ({
    categoryId: cat.id,
    categoryName: cat.name,
    categoryDescription: cat.description,
    groupId: cat.groupId,
    groupName: cat.groupName,
    groupPosition: cat.groupPosition,
    scale: cat.scale,
    requireReason: cat.requireReason,
    multipleRequireReasons: cat.multipleRequireReasons,
    requireReasonVisibility: cat.requireReasonVisibility,
    freeTextAllowed: cat.freeTextAllowed,
    weight: cat.weighting,
    critical: cat.failCategory,
    position: cat.position,
    isArchived: cat.isArchived,
    scorecards: cat.scorecards,
    rootCauses: cat.rootCauses,
    autoQaCategory: cat.autoQaCategory,
    autoQaCustomCategoryTemplateId: cat.autoQaCustomCategoryTemplateId,
  }))
}

export async function loadFeedback(language?: string) {
  if (!sidebarState.connectionId || !sidebarState.conversationId) return

  sidebarState.loadingFeedback = true

  const settingEnabled = session.workspace.settings.calibrationEnabled
  const isCalibrationEnabled = session.features.calibrationPro && settingEnabled

  let conversationFeedback: Feedback[] = []

  if (calibrationSessionId.value && isCalibrationEnabled) {
    const { feedback } = await getCalibrationFeedback(
      sidebarState.conversationId,
      sidebarState.connectionId,
      calibrationSessionId.value,
    )
    conversationFeedback = feedback
  } else {
    const { feedback } = await getFeedback({
      connectionId: sidebarState.connectionId,
      conversationId: sidebarState.conversationId,
      language,
    })
    conversationFeedback = feedback
  }

  sidebarState.feedbackList = conversationFeedback
  sidebarState.loadingFeedback = false

  return conversationFeedback
}

function updateScore() {
  const scores = validReviews.value.map((feedback) => feedback.score)
  const averageScore = scores.reduce((sum, score) => sum + score, 0) / scores.length
  sidebarState.feedbackScore = averageScore
}

function updateAutoQaFeedbackScore() {
  sidebarState.autoQaFeedbackScore = visibleAutoQaFeedback.value.length
    ? visibleAutoQaFeedback.value.reduce((sum, f) => sum + f.score, 0) / visibleAutoQaFeedback.value.length
    : 0
}

export async function loadTicketSurveys() {
  if (!sidebarState.connectionId || !sidebarState.conversationId) return

  const surveys = await getTicketSurveys(sidebarState.conversationId, sidebarState.connectionId)

  sidebarState.surveys = surveys.types.reduce((result, survey) => {
    if (survey.responses.length) {
      survey.responses.forEach((response) => {
        result.push({
          surveyName: survey.name,
          ...response,
        })
      })
    }

    return result
  }, [])

  return surveys
}

async function loadAutoQa() {
  if (!session.features.autoQa || !sidebarState.connectionId || !sidebarState.conversationId) return

  sidebarState.autoQaReviews = await getAutoQaConversationReviews({
    connectionId: sidebarState.connectionId.toString(),
    conversationId: sidebarState.conversationId,
  })
}

export async function stopAndSetReviewTime(params: StopParams) {
  const id = params.reviewId ? String(params.reviewId) : params.calibrationReviewId
  const review = sidebarState.feedbackList.find((f) => f.id === id)
  if (!review) return

  if (!params.calibrationReviewId) review.reviewTime = 'loading'

  const { reviewTime } = await timeTracking.stop(params)
  if (reviewTime && !params.calibrationReviewId) review.reviewTime = reviewTime
}

async function initWorkspaceUserList() {
  sidebarState.workspaceUsersLoading = true
  const { users } = await searchUsers({ workspaceIds: [session.workspace.id], limit: 20000 })
  sidebarState.workspaceUsers = users
  sidebarState.workspaceUsersLoading = false
}
