import { computed, nextTick, reactive, ref, watch } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import { useRoute, useRouter } from 'vue-router'
import { sortBy } from 'lodash-es'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import type { StateChanger } from 'v3-infinite-loading'
import {
  AssignmentV2_GetUserAssignmentConversationsResponse_ConversationStatus as Status,
  type AssignmentV2_GetUserAssignmentConversationsResponse_TicketListItem as AssignmentTaskItem,
  type AssignmentV2_UserAssignment,
} from '@klausapp/services/assignments_v2'

import analytics from '@/utils/analytics'
import { bus } from '@/utils/bus'
import { getCalibrationSessions as apiGetCalibrationSessions, type CalibrationSession } from '@/api/calibration'
import { session } from '@/composables/useSession'
import { sidebarState, resetConversation } from '@/composables/useReviewSidebar'
import { getAssignmentConversations, getAssignments } from '@/modules/tasks/api'
import { analyticsLabels, type SortValues } from '@/modules/tasks/utils/assignmentOptions'
import { defaultState, type State } from '@/modules/tasks/types'
import { RootViews } from '@/types/pageLayout'
import { hasWorkspaceRole, hasAccountRole } from '@/utils/roleUtils'
import { getCalibrationConversations } from '@/modules/shared/TicketContent/api/calibration'
import type { TicketListItemExt } from '@/modules/conversations/types'
import { setLayoutState } from './usePageLayout'

interface StoragePayload {
  assignmentId: string
  conversationId: string
  connectionId: string | number
  allowedReviewees?: string[]
}

dayjs.extend(isToday)

export const TASKS_STORAGE_KEY = 'klausExtensionAssignments:v2'
// Keeping reviewees in a separate storage to avoid conflicts with old assignments; these can be consolidated in the future
export const TASKS_REVIEWEES_STORAGE_KEY = 'klausExtensionAssignmentsReviewees:v1'

export default createSharedComposable(() => {
  const route = useRoute()
  const router = useRouter()

  const state = reactive<State>(defaultState)
  const lazyLoadingKey = computed(
    () =>
      `${state.list.type === 'assignment' ? state.activeAssignment?.id : state.activeCalibrationSession?.sessionId}-${
        state.listParams.sort
      }-${state.listParams.status}`,
  )
  const previousTicket = computed(() => getTicketByIndex(-1))
  const nextTicket = computed(() => getTicketByIndex(1))
  const statusesWithoutActions = [Status.REMOVED, Status.DONE]

  const isAssignmentTasksView = computed(
    () => route.name.toString().startsWith('tasks.assignments') && !!route.params?.assignmentId,
  )
  const canCreateAssignmentReview = computed(() => {
    if (!route.params.conversationId || !state.list.items.length) return false

    const conversation = (state.list.items as AssignmentTaskItem[]).find(
      (conv) => conv.externalId === route.params.conversationId,
    )

    if (statusesWithoutActions.includes(conversation?.ticketStatus?.status)) return false

    if (state.activeAssignment?.settings?.goal.config.assigned)
      return conversation?.assignedReviewer?.id === session.user.id.toString()

    return true
  })

  const currFinished = ref<AssignmentV2_UserAssignment[]>([])
  const excludeCurrFinished = (a: AssignmentV2_UserAssignment) => !currFinished.value.includes(a)

  const activeAssignmentCycles = computed(() => {
    const allAssignments = [
      ...currFinished.value,
      ...(state.allAssignments.filter((a) => a.reviewCount !== a.goal).filter(excludeCurrFinished) || []),
    ]

    return sortBy(allAssignments, 'cycleEnd')
  })

  const completedAssignmentCycles = computed(
    () => state.allAssignments.filter((a) => !a.goal || a.reviewCount === a.goal).filter(excludeCurrFinished) || [],
  )

  const canSeeCalibrations = computed(() => {
    const hasRequiredRole =
      hasWorkspaceRole('MANAGER', 'LEAD', 'REVIEWER') ||
      (!session.workspace?.role && hasAccountRole('ADMIN', 'MANAGER'))
    const featureEnabled = session.features.calibrationPro
    const settingEnabled = session.workspace.settings.calibrationEnabled

    return hasRequiredRole && featureEnabled && settingEnabled
  })
  const calibrationSessionDetails = computed(
    () =>
      state.allCalibrationSessions?.find(
        ({ sessionId }) => sessionId.toString() === state.activeCalibrationSession?.sessionId,
      ) || {
        title: '',
        dueAt: '',
        createdBy: null,
        createdAt: '',
        updatedBy: null,
        updatedAt: '',
      } /* default session */,
  )
  const activeSessions = computed(() =>
    state.allCalibrationSessions?.filter(
      (session) => dayjs(session.dueAt).isAfter(dayjs(), 'day') || dayjs(session.dueAt).isToday(),
    ),
  )
  const pastSessions = computed(() =>
    state.allCalibrationSessions?.filter((session) => dayjs(session.dueAt).isBefore(dayjs(), 'day')),
  )

  function getConversationRoute({ params, query }) {
    const ticketParams = {
      connectionId: params.connectionId,
      conversationId: params.conversationId,
      ...(params.messageId ? { messageId: encodeURIComponent(params.messageId) } : {}),
    }

    let name = state.list.type === 'assignment' ? 'tasks.assignments.review' : 'tasks.calibrations.review'
    if (route.name?.toString().startsWith('extension')) name = 'extension.tasks'

    return {
      name,
      params: {
        ...ticketParams,
        ...(state.list.type === 'calibration'
          ? { calibrationSessionId: encodeURIComponent(params.calibrationSessionId) }
          : { assignmentId: encodeURIComponent(params.assignmentId) }),
      },
      query,
    }
  }

  function navigateToTicket(ticket: AssignmentTaskItem | TicketListItemExt, evt?: KeyboardEvent | MouseEvent) {
    if (ticket) {
      router.push(
        getConversationRoute({
          params: {
            connectionId: ticket.paymentTokenId,
            conversationId: ticket.externalId,
            ...(state.list.type === 'calibration'
              ? { calibrationSessionId: route.params.calibrationSessionId }
              : { assignmentId: route.params.assignmentId }),
          },
          query: route.query,
        }),
      )
      if (evt) analytics.shortcutUsed('key' in evt ? evt.key : 'click')
    }
  }

  function redirectToConversation() {
    const isCorrectCalibrationRoute =
      state.activeCalibrationSession?.sessionId &&
      state.activeCalibrationSession?.sessionId === route.params.calibrationSessionId
    const isCorrectAssignmentRoute =
      state.activeAssignment?.id && state.activeAssignment?.id === route.params.assignmentId

    if (
      ((state.list.type === 'assignment' && isCorrectAssignmentRoute) ||
        (state.list.type === 'calibration' && isCorrectCalibrationRoute)) &&
      state.list.items.length &&
      sidebarState.conversationId &&
      sidebarState.connectionId
    )
      return

    if (state.list.type === 'assignment') {
      state.activeAssignment = route.params.assignmentId
        ? state.allAssignments?.find((assignment) => assignment?.id === route.params.assignmentId)
        : state.allAssignments[0]
    } else if (state.list.type === 'calibration') {
      state.activeCalibrationSession = route.params.calibrationSessionId
        ? state.allCalibrationSessions?.find((session) => session.sessionId === route.params.calibrationSessionId)
        : state.allCalibrationSessions[0]
    }

    const firstTicketRoute = getConversationRoute({
      params: {
        ...(state.list.type === 'assignment'
          ? { assignmentId: state.activeAssignment?.id }
          : { calibrationSessionId: state.activeCalibrationSession?.sessionId }),
        connectionId: sidebarState.connectionId || Number(state.list.items[0]?.paymentTokenId) || undefined,
        conversationId: sidebarState.conversationId || Number(state.list.items[0]?.externalId) || undefined,
      },
      query: route.query,
    })

    const currentlyActive = state.list.type === 'assignment' ? state.activeAssignment : state.activeCalibrationSession
    if (!currentlyActive || !state.list.items.length) {
      state.list.isLoading = false
      sidebarState.loadingFeedback = false
    } else if (firstTicketRoute) router.replace(firstTicketRoute)
  }

  function getTicketByIndex(indexModifier: number) {
    if (!state.list.items.length) return

    const index =
      state.list.items.findIndex(
        (t) => t.externalId === route.params.conversationId && t.paymentTokenId === route.params.connectionId,
      ) + indexModifier

    return index !== -1 && state.list.items[index]
  }

  function navigateToPreviousTicket(evt: KeyboardEvent | MouseEvent) {
    navigateToTicket(previousTicket.value, evt)
  }

  function navigateToNextTicket(evt?: KeyboardEvent | MouseEvent) {
    navigateToTicket(nextTicket.value, evt)
  }

  function setAssignment(assignmentId: string) {
    const newAssignment = state.allAssignments?.find((a) => a.id === assignmentId)

    if (newAssignment) state.activeAssignment = newAssignment
  }

  async function getConversations(assignmentId: string) {
    if (!assignmentId) return
    if (state.list.isFilterLoading) return

    const { conversations, total, seed } = await getAssignmentConversations(assignmentId, state.listParams)

    if (assignmentId !== route.params.assignmentId) return

    state.list.items = (conversations as AssignmentTaskItem[]) || []
    state.list.total = String(total)
    state.listParams.seed = seed
    sidebarState.loadingFeedback = false
  }

  async function sortAssignments(sortValue: SortValues) {
    state.listParams.sort = sortValue
    state.listParams.page = 0

    await getConversations(state.activeAssignment?.id)
    analytics.assignmentListSorted(analyticsLabels[sortValue])
  }

  function setLoadingState(setTicketAsLoading?: boolean) {
    state.list.isLoading = true
    if (setTicketAsLoading) bus.$emit('ticket-show-loader')
    sidebarState.loadingFeedback = true
  }

  async function getAllAssignments(assignmentId?: string) {
    setLoadingState(true)

    const res = await getAssignments()

    state.allAssignments = sortBy(res.assignments, 'cycleEnd') || []

    if (!res.assignments.length) {
      state.list.isLoading = false
      sidebarState.loadingFeedback = false
      return
    }
    if (assignmentId) state.activeAssignment = res.assignments.find((a) => a.id === assignmentId)
    else state.activeAssignment = res.assignments[0]
  }

  async function getAssignmentConversationsList(assignmentId?: string, setTicketAsLoading = true) {
    state.listParams.page = 0

    if (!state.list.isLoading) setLoadingState(setTicketAsLoading)
    if (state.activeAssignment?.id && assignmentId !== state.activeAssignment?.id) {
      setAssignment(assignmentId)
    }

    if (setTicketAsLoading) state.changedListItem = []

    await getConversations(state.activeAssignment?.id)

    state.list.isLoading = false
    sidebarState.loadingFeedback = false
  }

  function selectAssignment(assignment: AssignmentV2_UserAssignment) {
    if (assignment.id === state.activeAssignment?.id) return

    getAssignmentConversationsList(assignment?.id)
  }

  async function getCalibrationSessions(calibrationSessionId?: string): Promise<CalibrationSession[]> {
    if (!canSeeCalibrations.value) return []

    const { data } = await apiGetCalibrationSessions(session.workspace.id)

    state.allCalibrationSessions = data

    if (calibrationSessionId) state.activeCalibrationSession = data.find((a) => a.sessionId === calibrationSessionId)
    else state.activeCalibrationSession = data[0]
  }

  async function getCalibrationConversationList(sessionId?: string, setTicketAsLoading = true) {
    if (!state.list.isLoading) setLoadingState(setTicketAsLoading)

    if (sessionId)
      state.activeCalibrationSession = state.allCalibrationSessions?.find((session) => session.sessionId === sessionId)

    if (!state.activeCalibrationSession?.sessionId) return

    const { tickets } = await getCalibrationConversations({ sessionId: state.activeCalibrationSession?.sessionId })

    state.list.items = tickets || []
    state.list.total = String(tickets.length)
    state.list.isLoading = false
    sidebarState.loadingFeedback = false
  }

  function loadNextPage($loader: StateChanger) {
    state.infiniteLoader = $loader
    state.listParams.page += 1
  }

  function getDefaultReviewee(activeConversation: AssignmentTaskItem, assigneeId: number) {
    const { assignedReviewees, assignedBotReviewees } = activeConversation

    if (assignedReviewees?.length) return Number(assignedReviewees[0].id)
    if (assignedBotReviewees?.length) return assignedBotReviewees[0].id

    return assigneeId
  }

  function setDefaultReviewee() {
    if (!route.params.conversationId || !state.list?.items?.length) return

    const activeConversation = (state.list.items as AssignmentTaskItem[])?.find(
      (item) => item.externalId === route.params.conversationId,
    )
    const assigneeId =
      sidebarState.ticket?.assignee?.externalId === activeConversation?.assigneeId
        ? Number(sidebarState.ticket?.assignee?.internalId)
        : null

    if (activeConversation) {
      sidebarState.defaultReviewee = getDefaultReviewee(activeConversation, assigneeId)

      if (route.name?.toString().startsWith('tasks.calibrations')) {
        sidebarState.assignmentRevieweeIds = []
        return
      }

      sidebarState.assignmentRevieweeIds = [
        assigneeId || null,
        ...(activeConversation.assignedReviewees.length
          ? activeConversation.assignedReviewees.map((r) => Number(r.id))
          : []),
      ].filter((i) => i)
    }
  }

  function getStorageKey(connectionId: number | string, conversationId: string) {
    return [session.user.id, session.account.id, session.workspace.id, connectionId, conversationId].join('.')
  }

  function persistAssignment({ assignmentId, conversationId, connectionId, allowedReviewees }: StoragePayload) {
    const savedAssignments = JSON.parse(sessionStorage[TASKS_STORAGE_KEY] || '{}')
    const savedReviewees = JSON.parse(sessionStorage[TASKS_REVIEWEES_STORAGE_KEY] || '{}')

    savedAssignments[getStorageKey(connectionId, conversationId)] = assignmentId
    savedReviewees[assignmentId] = allowedReviewees

    sessionStorage[TASKS_STORAGE_KEY] = JSON.stringify(savedAssignments)
    sessionStorage[TASKS_REVIEWEES_STORAGE_KEY] = JSON.stringify(savedReviewees)
  }

  function removePersistedAssignment({ conversationId, connectionId }: StoragePayload) {
    if (!sessionStorage[TASKS_STORAGE_KEY]) return

    const savedAssignments = JSON.parse(sessionStorage[TASKS_STORAGE_KEY])
    const key = getStorageKey(connectionId, conversationId)

    if (key in savedAssignments) {
      delete savedAssignments[key]
      sessionStorage[key] = JSON.stringify(savedAssignments)
    }
  }

  watch(
    () => state.listParams.page,
    async () => {
      if (
        state.listParams.page === 0 ||
        state.list.type !== 'assignment' ||
        (!route.name?.toString().startsWith('tasks') && !route.name?.toString().startsWith('extension.tasks'))
      )
        return

      const res = await getAssignmentConversations(state.activeAssignment?.id, state.listParams)

      if (!res.conversations.length) {
        state.infiniteLoader?.complete()
        return
      }

      state.infiniteLoader?.loaded()

      state.list.items = [...state.list.items, ...res.conversations] as AssignmentTaskItem[]
      state.list.total = String(res.total)
      state.listParams.seed = res.seed
      sidebarState.loadingFeedback = false
    },
  )

  watch(
    () => route.name,
    (newName, oldName) => {
      if (!oldName) return
      const newIsAssignments = newName.toString().startsWith('tasks.assignments')
      const oldIsAssignments = oldName.toString().startsWith('tasks.assignments')

      if (!newIsAssignments && !oldIsAssignments) return

      const switchingBetweenRoutes = newIsAssignments !== oldIsAssignments

      if (switchingBetweenRoutes) {
        state.list.items = []
        if (newIsAssignments) state.activeCalibrationSession = undefined
        if (oldIsAssignments) state.activeAssignment = undefined
      }
    },
  )

  watch(
    () => [route.params.assignmentId, route.params.calibrationSessionId],
    async ([assignmentId, calibrationSessionId]) => {
      await nextTick()

      if (!route.name?.toString().startsWith('tasks')) return

      state.list.type = route.name?.toString().startsWith('tasks.assignments') ? 'assignment' : 'calibration'
      state.listParams.seed = ''

      if (
        state.list.type === 'assignment' &&
        (!assignmentId || state.activeAssignment?.id !== assignmentId || !state.list.items.length)
      ) {
        await getAssignmentConversationsList(assignmentId?.toString() || undefined)

        if (assignmentId !== route.params.assignmentId) return

        state.activeCalibrationSession = undefined
      } else if (
        state.list.type === 'calibration' &&
        (!calibrationSessionId ||
          state.activeCalibrationSession?.sessionId !== calibrationSessionId ||
          !state.list.items.length)
      ) {
        await getCalibrationConversationList(calibrationSessionId?.toString() || undefined)

        if (calibrationSessionId !== route.params.calibrationSessionId) return

        state.activeAssignment = undefined
      }

      if (
        !state.list.items?.length ||
        !state.list.items?.find(
          (item) =>
            item.externalId === route.params.conversationId && item.paymentTokenId === route.params.connectionId,
        )
      ) {
        resetConversation()

        if (!state.list.items?.length) {
          return setLayoutState(RootViews.Tasks, { leftSidebar: 'list', rightSidebar: undefined })
        }
      }

      redirectToConversation()

      state.changedListItem = []

      sidebarState.metadata.assignmentId =
        state.list.type === 'assignment' && route.params.assignmentId ? String(route.params.assignmentId) : undefined

      setDefaultReviewee()
    },
    { immediate: true },
  )

  watch(
    () => [state.list.items],
    () => {
      if (!route.name?.toString().startsWith('tasks')) return

      if (
        !['assignment', 'calibration'].includes(state.list.type) ||
        !(state.list.isLoading && state.list.items?.length)
      )
        return bus.$emit('ticket-hide-loader')

      redirectToConversation()

      sidebarState.metadata.assignmentId =
        state.list.type === 'assignment' && route.params.assignmentId ? String(route.params.assignmentId) : undefined

      if (state.list.items?.length) setDefaultReviewee()

      bus.$emit('ticket-hide-loader')
    },
    { immediate: true, deep: true },
  )

  watch(
    () => sidebarState.ticket?.assignee,
    () => route.name?.toString().startsWith('tasks') && setDefaultReviewee(),
  )

  watch(
    () => state.activeAssignment?.id,
    (newId, oldId) => {
      if (!newId || newId === oldId) return
      currFinished.value = []
    },
  )

  return {
    /* all */
    state,
    isAssignmentTasksView,
    canCreateAssignmentReview,
    lazyLoadingKey,
    loadNextPage,
    previousTicket,
    nextTicket,
    navigateToNextTicket,
    navigateToPreviousTicket,
    redirectToConversation,
    currFinished,
    /* assignments */
    activeAssignmentCycles,
    completedAssignmentCycles,
    sortAssignments,
    getAllAssignments,
    getAssignmentConversationsList,
    selectAssignment,
    statusesWithoutActions,
    /* extension */
    getStorageKey,
    persistAssignment,
    removePersistedAssignment,
    /* calibrations */
    activeSessions,
    pastSessions,
    canSeeCalibrations,
    calibrationSessionDetails,
    getCalibrationSessions,
    getCalibrationConversationList,
  }
})
