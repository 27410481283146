import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "text-gray-70 text-sm font-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_icon = _resolveComponent("search-icon")!
  const _component_folder_icon = _resolveComponent("folder-icon")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createVNode(_component_q_select, {
      "model-value": _ctx.selectedUserId,
      mode: "single",
      class: "mb-1 w-full",
      "field-label": _ctx.$t('pins.modal.pin_under'),
      "data-testid": "pins-folder-picker",
      placeholder: _ctx.$t('pins.modal.search_user'),
      options: _ctx.usersList,
      "no-options-text": _ctx.$t('universal.no_users_found'),
      "filter-results": false,
      "onUpdate:modelValue": _ctx.handleInput,
      onSearchChange: _cache[0] || (_cache[0] = ($event) => (_ctx.query = $event))
    }, {
      option: _withCtx(({ option }) => [
        (option.id === 'separator')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.separator)
            }, null, 2 /* CLASS */))
          : _createCommentVNode("v-if", true)
      ]),
      icon: _withCtx(({ open }) => [
        open
          ? (_openBlock(), _createBlock(_component_search_icon, {
              key: 0,
              class: "icon-m"
            }))
          : (_openBlock(), _createBlock(_component_folder_icon, {
              key: 1,
              class: "icon-m"
            }))
      ]),
      afterlist: _withCtx(() => [
        (_ctx.remainingUsers)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(_ctx.$style.more)
            }, _toDisplayString(_ctx.$t('universal.n_more', { n: _ctx.$n(Number(_ctx.remainingUsers)) }, Number(_ctx.remainingUsers))), 3 /* TEXT, CLASS */))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["model-value", "field-label", "placeholder", "options", "no-options-text", "onUpdate:modelValue"]),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('pins.modal.who_can_see_pins')), 1 /* TEXT */)
  ], 2 /* CLASS */))
}