import { api, oldApi } from '@/api'
import { session } from '@/composables/useSession'
import type { Scorecard } from '@/modules/shared/TicketContent/types/scorecard'
import type { AutoQaCategoryType, FullCategory } from '@/types/category'
import type { ScaleType } from '@/data/scales'

export interface ActiveRatingCategory {
  id: string
  name: string
  description: string
  scale: string
}

export interface RatingCategoryGroup {
  created: string
  id: number
  name: string
  ownerId: number
  paymentId: number
  position: number
  teamId: number
  updated: string
  updatedBy: number
}

interface RatingCategoryGroups {
  data: RatingCategoryGroup[]
}

export interface Category extends FullCategory {
  scorecards: Scorecard[]
}

interface NewScorecard {
  tag: string
}

export interface CategoryPayload extends FullCategory {
  scorecards: (Scorecard | NewScorecard)[]
}

export interface TeamScorecard {
  id: number
  ratingCategoryIds: number[]
  tag: string
}
export interface TeamScorecards {
  data: TeamScorecard[]
  total: number
}

export interface DynamicScorecardOption {
  allowedMultiple: boolean
  conditions: { condition: string; labelCode: string }[]
  type: string
  values: string[]
}

interface BaseAutoQaCategory {
  scales: ScaleType[]
  causes: string[]
  customPhrasesCount: number
}

export type AutoQaCategory = BaseAutoQaCategory & {
  name: AutoQaCategoryType
}

export type CustomAutoQaCategory = BaseAutoQaCategory & {
  customCategoryTemplateId: string
  customCategoryTemplateName: string
}

const getCategoryPath = (workspaceId: number) => `payments/${session.account.id}/teams/${workspaceId}/rating-categories`
const getCategoryPathV2 = (workspaceId: number) =>
  `payments/${session.account.id}/teams/${workspaceId}/v2/rating-categories`

const getGroupPath = (workspaceId: number) =>
  `payments/${session.account.id}/teams/${workspaceId}/rating-category-groups`

export const getCategories = (workspaceId: number) =>
  oldApi.get(getCategoryPath(workspaceId)).json<{ data: Category[]; total: number }>()

export const getActiveCategories = (workspaceId: number) =>
  api
    .get('workspaces/active-categories', { headers: { 'X-Klaus-Workspace': workspaceId.toString() } })
    .json<{ categories: ActiveRatingCategory[] }>()

export const createCategory = (workspaceId: number, data: Partial<CategoryPayload>) =>
  oldApi.post(`${getCategoryPathV2(workspaceId)}`, { json: data }).json<{ data: number }>()

export const updateCategory = (workspaceId: number, categoryId: number, data: Partial<CategoryPayload>) =>
  oldApi.put(`${getCategoryPathV2(workspaceId)}/${categoryId}`, { json: data }).json<{ data: number }>()

export const deleteCategory = (workspaceId: number, categoryId: number) =>
  oldApi.delete(`${getCategoryPath(workspaceId)}/${categoryId}`).json<{ data: FullCategory[] }>()

export const archiveCategory = (workspaceId: number, categoryId: number) =>
  oldApi.put(`${getCategoryPath(workspaceId)}/${categoryId}/archive`).json<{ data: FullCategory[] }>()

export const unarchiveCategory = (workspaceId: number, categoryId: number) =>
  oldApi.put(`${getCategoryPath(workspaceId)}/${categoryId}/unarchive`).json<{ data: FullCategory[] }>()

export const duplicateCategory = (workspaceId: number, categoryId: number) =>
  oldApi.put(`${getCategoryPathV2(workspaceId)}/${categoryId}/duplicate`).json<{ data: FullCategory[] }>()

export const reorderCategory = (workspaceId: number, categoryId: number, position: number) =>
  oldApi.put(`${getCategoryPath(workspaceId)}/${categoryId}/position/${position}`).json()

export const getCategoryGroups = (workspaceId: number) =>
  oldApi.get(`${getGroupPath(workspaceId)}`).json<RatingCategoryGroups>()

export const createCategoryGroup = (workspaceId: number, data: { name: string }) =>
  oldApi.post(`${getGroupPath(workspaceId)}`, { json: data }).json<{ data: RatingCategoryGroup[] }>()

export const updateCategoryGroup = (workspaceId: number, groupId: number, data: { name: string }) =>
  oldApi.put(`${getGroupPath(workspaceId)}/${groupId}`, { json: data }).json<{ data: RatingCategoryGroup[] }>()

export const deleteCategoryGroup = (workspaceId: number, groupId: number, deleteCategories: boolean) =>
  oldApi
    .delete(`${getGroupPath(workspaceId)}/${groupId}?deleteRatingCategories=${deleteCategories}`)
    .json<{ data: number }>()

export const reorderCategoryGroup = (workspaceId: number, groupId: number, position: number) =>
  oldApi.put(`${getGroupPath(workspaceId)}/${groupId}/position/${position}`).json()

export const moveCategoryToGroup = (workspaceId: number, groupId: number, categoryId: number, position: number) =>
  oldApi.put(`${getGroupPath(workspaceId)}/${groupId}/add/${categoryId}`, { json: { position } }).json()

export const removeCategoryFromGroup = (workspaceId: number, groupId: number, categoryId: number, position: number) =>
  oldApi.put(`${getGroupPath(workspaceId)}/${groupId}/remove/${categoryId}`, { json: { position } }).json()

export const getTeamScorecards = (workspaceId: number) =>
  oldApi.get(`payments/${session.account.id}/teams/${workspaceId}/team-scorecards`).json<TeamScorecards>()

export const getDynamicScorecardOptions = (workspaceId: number) =>
  api
    .get('workspaces/dynamic-scorecard-options', { headers: { 'X-Klaus-Workspace': workspaceId.toString() } })
    .json<{ options: DynamicScorecardOption[] }>()

export const getAutoQaCategories = (workspaceId: number) =>
  oldApi
    .get(`payments/${session.account.id}/teams/${workspaceId}/auto-qa-rating-categories`)
    .json<{ data: (AutoQaCategory | CustomAutoQaCategory)[] }>()
