import * as Sentry from '@sentry/vue'

import { oldApi } from '@/api'
import { session, loadFreshTokens } from '@/composables/useSession'

import { parseInviteTokenPayload, registerUser } from '../api'

/**
 * Sign up with inviteToken if stored in Auth0 user meta
 */
export async function registerWithStoredInviteToken() {
  const [accessToken, idToken] = await loadFreshTokens()

  // Handle stored invite token claim from Auth0 email confirmation flow for new accounts
  // TODO: API should clean up this from user metadata
  const emailInviteToken = session.idTokenPayload['https://login.klausapp.com/emailInviteToken']
  if ((!session.account || (!session.account.role && !session.workspace.role)) && emailInviteToken) {
    let inviteValid = false
    try {
      const { data } = await oldApi.get(`users/invite-data?inviteToken=${emailInviteToken}`).json<any>()
      inviteValid = !data.usedAt
    } catch (err) {
      Sentry.captureException(err)
    }

    if (inviteValid) {
      await registerUser({ idToken, accessToken, ...parseInviteTokenPayload(emailInviteToken) })
      window.location.href = '/'
      return true
    }
  }

  return false
}
