import config from '@/config'

export const whitelistedOrigins = (config.authWhitelistedExtensions || '')
  .split(',')
  .map((id) => `chrome-extension://${id}`)

const ancestors = location.ancestorOrigins || []

export const illegallyEmbedded =
  !('Cypress' in window) && ancestors.length && !whitelistedOrigins.includes(ancestors[0])

if (ancestors.length && !illegallyEmbedded) {
  config.embeddedRuntime = true
}

const extensionOriginPeristenceKey = 'klausExtensionOrigin.v1'

export const getExtensionOrigin = () => localStorage.getItem(extensionOriginPeristenceKey)

export const setExtensionOrigin = (value: string | null) =>
  value === null
    ? localStorage.removeItem(extensionOriginPeristenceKey)
    : localStorage.setItem(extensionOriginPeristenceKey, value)
