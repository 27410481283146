import { defineAsyncComponent } from 'vue'
import { toast } from '@/components/toast'
import i18n from '@/i18n'

export default defineAsyncComponent({
  loader: () => import('./CommandPalette.vue'),
  loadingComponent: () => {
    toast({ status: 'info', message: i18n.t('components.command_palette.loading') })
  },
  delay: 200,
  timeout: 60000,
  onError: (_error, retry, _fail, attempts) => {
    if (attempts < 2) return retry()
    toast({ status: 'error', message: i18n.t('components.command_palette.failed_to_load'), timeout: undefined })
  },
})
