import i18n from '@/i18n'

export const controlKey = navigator.userAgent.includes('Mac') ? '⌘' : 'Ctrl'
export const newPinCombo = [controlKey, 'P']
export const togglePageNavCombo = ['⇧', 'S']
export const toggleCommandPaletteCombo = [controlKey, 'K']

export default () => [
  {
    combo: ['⇧', '?'],
    description: i18n.t('modules.application.shortcuts.toggle_shortcuts'),
  },
  {
    combo: toggleCommandPaletteCombo,
    description: i18n.t('modules.application.shortcuts.command_palette'),
  },
  {
    combo: togglePageNavCombo,
    description: i18n.t('modules.application.shortcuts.hide_show_subnav'),
  },
  {
    combo: ['→'],
    alternative: ['J'],
    description: i18n.t('modules.application.shortcuts.next_conversation'),
  },
  {
    combo: ['←'],
    alternative: ['K'],
    description: i18n.t('modules.application.shortcuts.previous_conversation'),
  },
  {
    combo: ['R'],
    description: i18n.t('modules.application.shortcuts.shuffle_list'),
  },
  {
    combo: [controlKey, '⇧', 'E'],
    description: i18n.t('modules.application.shortcuts.expand_editor'),
  },
  {
    combo: [controlKey, '⇧', 'O'],
    description: i18n.t('modules.application.shortcuts.open_original_conversation'),
  },
  {
    combo: [controlKey, '⇧', '↑'],
    description: i18n.t('modules.application.shortcuts.rate_highest'),
  },
  {
    combo: [controlKey, '⇧', '↓'],
    description: i18n.t('modules.application.shortcuts.rate_lowest'),
  },
  {
    combo: newPinCombo,
    description: i18n.t('modules.application.shortcuts.add_pin'),
  },
]
