/*
 * Scrollbar Width Test
 * Adds `layout-scrollbar-obtrusive` class to body if scrollbars use up screen real estate
 */
{
  const parent = document.createElement('div')
  parent.setAttribute('style', 'width:30px; height:30px; overflow-y: auto;')

  const child = document.createElement('div')
  child.setAttribute('style', 'width:100%; height:40px')
  parent.appendChild(child)
  document.body.appendChild(parent)

  const scrollbarWidth = 30 - (parent && parent.firstElementChild ? parent.firstElementChild.clientWidth : 0)
  if (scrollbarWidth) document.body.classList.add('layout-scrollbar-obtrusive')

  document.body.removeChild(parent)
}

export {}
