import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_dropdown = _resolveComponent("ui-dropdown")!
  const _component_q_tag_input = _resolveComponent("q-tag-input")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([_ctx.$style.conditionContainer, "mt-6"])
      }, [
        _createVNode(_component_ui_dropdown, {
          modelValue: _ctx.filter.condition,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.condition) = $event)),
          class: _normalizeClass(_ctx.$style.conditionDropdown),
          options: _ctx.dropdownOpts
        }, null, 8 /* PROPS */, ["modelValue", "class", "options"]),
        _createVNode(_component_q_tag_input, {
          modelValue: _ctx.filter.list,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.list) = $event)),
          class: _normalizeClass(_ctx.$style.conditionInput),
          label: _ctx.label,
          name: _ctx.name,
          placeholder: _ctx.placeholder
        }, null, 8 /* PROPS */, ["modelValue", "class", "label", "name", "placeholder"])
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}