export enum TimestampType {
  Start = 'START',
  Pause = 'PAUSE',
  Stop = 'STOP',
}

export interface StopParams {
  reviewId?: number
  calibrationReviewId?: string
  isCalibration?: boolean
}

export interface PauseParams {
  limitPause: number
}

export interface StopResponse {
  reviewTime: string | null
}
