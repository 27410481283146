import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = ["selected"]
const _hoisted_3 = ["value", "disabled", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("\n      triggered after selection change\n      @event input\n    "),
    _createElementVNode("select", _mergeProps({
      class: ["text-sm leading-6", [_ctx.$style.select, { [_ctx.$style.placeholder]: _ctx.modelValue === '', [_ctx.$style.error]: _ctx.error }]]
    }, _ctx.$attrs, {
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
    }), [
      (_ctx.placeholder !== '')
        ? (_openBlock(), _createElementBlock("option", {
            key: 0,
            value: "",
            disabled: "",
            selected: _ctx.modelValue === ''
          }, _toDisplayString(_ctx.placeholder), 9 /* TEXT, PROPS */, _hoisted_2))
        : _createCommentVNode("v-if", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownOptions, (o) => {
        return (_openBlock(), _createElementBlock("option", {
          key: o.value,
          value: o.value,
          disabled: o.disabled,
          selected: _ctx.modelValue === o.value
        }, _toDisplayString(o.label), 9 /* TEXT, PROPS */, _hoisted_3))
      }), 128 /* KEYED_FRAGMENT */))
    ], 16 /* FULL_PROPS */, _hoisted_1)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}