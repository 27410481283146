import { computed, type Ref } from 'vue'
import { matchSorter } from 'match-sorter'
import { createEventHook } from '@vueuse/core'

import { Modes, type PropsWithDefaults, type UpdateParams, type OptionGroup } from '../types'

import useSingleList from './single/useSingleList'
import useMultipleList from './multiple/useMultipleList'

import { getGroupOptions } from './utils'

export default function useList<
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
>(props: PropsWithDefaults<T, P, L, M, V, O>, emit: any, searchQuery: Ref<string>) {
  const updateResults = createEventHook<UpdateParams<T, P, M, O>>()

  const handler = props.mode === Modes.Single ? useSingleList : useMultipleList

  const {
    allGroups,
    activeItemIndex,
    isSelected,
    isIndeterminate,
    reorderList,
    selectedIds,
    selectedOptions,
    allOptions,
    toggle,
    excludedValues,
    addOrSelectOption,
    onUpdate,
  } = handler<T, P, L, M, V, O>(props, emit, searchQuery)

  onUpdate(updateResults.trigger)

  const isEmpty = computed(() => !visibleOptions.value.length)

  const activeGroupIndex = computed(() => {
    let sum = 0
    const groups = filteredGroups.value

    for (let i = 0; i < groups.length; i++) {
      if (activeItemIndex.value >= 0 && activeItemIndex.value < sum + groups[i].options.length) return i
      sum += groups[i].options.length
    }

    return -1
  })

  const activeIndexInGroup = computed(() => {
    const prevGroupItems = filteredGroups.value
      .slice(0, activeGroupIndex.value)
      .reduce((sum, group) => sum + group.options.length, 0)

    return activeItemIndex.value - prevGroupItems
  })

  const visibleOptions = computed(() => getGroupOptions<T>(filteredGroups.value))

  const notSeparator = (option: T) => !('value' in option) || option.value !== 'separator'

  const getMatchingGroupOptions = (group: OptionGroup<T>) => {
    const filterableOptions = group.options.filter(notSeparator)

    return matchSorter(filterableOptions, searchQuery.value, { keys: [props.label as any] })
  }

  const filteredGroups = computed(() => {
    const shouldFilter = searchQuery.value && props.filterResults

    return allGroups.value.map((group: OptionGroup<T>) => ({
      ...group,
      options: shouldFilter ? getMatchingGroupOptions(group) : group.options,
    }))
  })

  return {
    activeGroupIndex,
    activeIndexInGroup,
    activeItemIndex,
    filteredGroups,
    isEmpty,
    isSelected,
    isIndeterminate,
    excludedValues,
    reorderList,
    selectedIds,
    selectedOptions,
    allOptions,
    toggle,
    addOrSelectOption,
    onUpdate: updateResults.on,
  }
}
