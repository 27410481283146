import { computed } from 'vue'

import type { PropsWithDefaults, Modes } from '../../types'

export default function useFlatOptions<
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
>(props: PropsWithDefaults<T, P, L, M, V, O>) {
  const flatOptions = computed(() => props.options || props.groups?.map((g) => g.options).flat() || [])

  return {
    flatOptions,
  }
}
