import { type Placement } from 'tippy.js'
import { SelectAllModes, DropdownButtonColor, DisplayModes } from './types'

export default {
  maxHeight: 300,
  triggerLabel: '',
  searchPlaceholder: '',
  noResultsText: '',
  loadingText: '',
  selectAllLabel: '',
  selectAllMode: SelectAllModes.All,
  total: undefined,
  maxWidth: 250,
  reorderAfterSelect: false,
  itemsLabel: '',
  size: 'md',
  excludedOptions: () => [],
  buttonColor: DropdownButtonColor.Default,
  placement: 'bottom-start' as Placement,
  display: DisplayModes.List,
}
