import { ref, watch, type Ref } from 'vue'
import { isEqual } from 'lodash-es'
import { createEventHook } from '@vueuse/core'

import { type PropsWithDefaults, Modes, type UpdateParams } from '../../types'

import { getIds, mapIdsToValues } from '../utils'

export default function useSelected<
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
>(props: PropsWithDefaults<T, P, L, M, V, O>) {
  const updateResults = createEventHook<UpdateParams<T, P, M, O>>()

  const selectedValues = ref(getIds<T, P, L, M, V, O>(props.modelValue, props)) as Ref<T[P][]>
  const excludedValues = ref(getIds<T, P, L, M, V, O>(props.excludedOptions, props)) as Ref<T[P][]>

  const updateModelValue = (selected: T[P][], excluded: T[P][], mode: Modes = Modes.Multiple) => {
    const selectedValuesArray = mapIdsToValues(selected, props)
    const excludedValuesArray = mapIdsToValues(excluded, props)
    if (!isEqual(props.modelValue, selectedValuesArray) || !isEqual(props.excludedOptions, excludedValuesArray))
      updateResults.trigger({
        selected: mode === Modes.Multiple ? selectedValuesArray : selectedValuesArray[0],
        excluded: mode === Modes.Multiple ? excludedValuesArray : excludedValuesArray[0],
      })
  }

  watch([() => props.modelValue, () => props.excludedOptions], ([selected, excluded]) => {
    selectedValues.value = getIds<T, P, L, M, V, O>(selected, props)
    excludedValues.value = getIds<T, P, L, M, V, O>(excluded, props)
  })

  return {
    selectedValues,
    excludedValues,
    updateModelValue,
    onUpdate: updateResults.on,
  }
}
