import { computed } from 'vue'
import i18n from '@/i18n'

const regionCodes = [
  'us-east-1',
  'us-east-2',
  'us-west-2',
  'ca-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-central-1',
  'eu-central-2',
  'ap-south-1',
  'ap-northeast-1',
  'ap-northeast-2',
  'ap-northeast-3',
  'ap-southeast-2',
  'sa-east-1',
  'me-central-1',
]

export const genesysRegionOptions = computed(() =>
  regionCodes.map((code) => ({
    name: `${i18n.t(`settings.connections.genesys_region.${code}`)} (${code})`,
    id: code,
  })),
)
