import { ref, onBeforeMount } from 'vue'
import type { UserGoal } from '@klausapp/services/users'

import { api } from '@/api'

const goalRef = ref<UserGoal>()

const reload = async () => {
  goalRef.value = await api.get('settings/goals').json<UserGoal>()
}

export const useUserGoal = (): [typeof goalRef, typeof reload] => {
  onBeforeMount(reload)
  return [goalRef, reload]
}
